import React, { useEffect, useState } from "react";
import Section from "../../../components/section/Section";
import SectionHead from "../../../components/section/SectionHead";
import SectionHeading from "../../../components/typography/SectionHeading";
import SectionContent from "../../../components/section/SectionContent";
import FormInput from "../../../components/form/FormInput";
import toDateFormated from "../../../functions/toDateFormated";
import useCookie from "../../../hooks/useCookie";
import Form from "../../../components/form/Form";
import FormInputHolder from "../../../components/form/FormInputHolder";
import FormSelect from "../../../components/form/FormSelect";
import useWarehouseProduct from "../../../hooks/useWarehouseProduct";
import useProductSelect from "../../../hooks/useProductSelect";
import FormError from "../../../components/form/FormError";
import FormLink from "../../../components/form/FormLink";
import FormSubmit from "../../../components/form/FormSubmit";
import apiRequest from "../../../api/apiRequest";
import FormNotice from "../../../components/form/FormNotice";

const PackMint = () => {
  const [isProduceable, setIsProduceable] = useState(false);
  const [packingDate, setPackingDate] = useCookie("packing-date");
  const [formLoading, setFormLoading] = useState(false);
  const [formError, setFormError] = useState();

  const [mintID, setMintID] = useState();
  const [mintAmount, setMintAmount] = useState();
  const [package05ID, setPackage05ID] = useState();
  const [package05Amount, setPackage05Amount] = useState("");
  const [package1ID, setPackage1ID] = useState();
  const [package1Amount, setPackage1Amount] = useState("");
  const [package5ID, setPackage5ID] = useState();
  const [package5Amount, setPackage5Amount] = useState("");

  const {
    warehouseProducts: warehouseMint,
    warehouseRefetch: warehouseMintRefetch,
  } = useWarehouseProduct(
    `product=66097824ec44f58429269729&amount=unused&date_produced=${packingDate}`
  );
  const warehouseMintSelect = useProductSelect(warehouseMint, "kg");

  const {
    warehouseProducts: warehousePackage05,
    warehouseRefetch: warehousePackage05Refetch,
  } = useWarehouseProduct(
    `product=660f2fc249a1b746df531ac1&amount=unused&date_produced=${packingDate}`
  );
  const warehousePackage05Select = useProductSelect(warehousePackage05, "kom");

  const {
    warehouseProducts: warehousePackage1,
    warehouseRefetch: warehousePackage1Refetch,
  } = useWarehouseProduct(
    `product=660f2fd449a1b746df531ac3&amount=unused&date_produced=${packingDate}`
  );
  const warehousePackage1Select = useProductSelect(warehousePackage1, "kom");

  const {
    warehouseProducts: warehousePackage5,
    warehouseRefetch: warehousePackage5Refetch,
  } = useWarehouseProduct(
    `product=660f3021bad3b41bfbe674fb&amount=unused&date_produced=${packingDate}`
  );
  const warehousePackage5Select = useProductSelect(warehousePackage5, "kom");

  useEffect(() => {
    if (warehouseMint && warehouseMint.length > 0) {
      const initialWarehouseMint = warehouseMint[0];
      const initialWarehouseMintID = initialWarehouseMint._id;
      setMintID(initialWarehouseMintID);
    } else {
      setIsProduceable(false);
    }
  }, [warehouseMint]);

  useEffect(() => {
    if (warehousePackage05 && warehousePackage05.length > 0) {
      const initialWarehousePackage05 = warehousePackage05[0];
      const initialWarehousePackage05ID = initialWarehousePackage05._id;
      setPackage05ID(initialWarehousePackage05ID);
    }
  }, [warehousePackage05]);

  useEffect(() => {
    if (warehousePackage1 && warehousePackage1.length > 0) {
      const initialWarehousePackage1 = warehousePackage1[0];
      const initialWarehousePackage1ID = initialWarehousePackage1._id;
      setPackage1ID(initialWarehousePackage1ID);
    }
  }, [warehousePackage1]);

  useEffect(() => {
    if (warehousePackage5 && warehousePackage5.length > 0) {
      const initialWarehousePackage5 = warehousePackage5[0];
      const initialWarehousePackage5ID = initialWarehousePackage5._id;
      setPackage5ID(initialWarehousePackage5ID);
    }
  }, [warehousePackage5]);

  useEffect(() => {
    warehouseMintRefetch();
    warehousePackage05Refetch();
    warehousePackage1Refetch();
    warehousePackage5Refetch();
  }, [packingDate]);

  useEffect(() => {
    if (package05Amount > 0 || package1Amount > 0 || package5Amount > 0) {
      setIsProduceable(true);
      setMintAmount(
        package05Amount * 0.5 + package1Amount * 1 + package5Amount * 5
      );
    } else {
      setIsProduceable(false);
      setMintAmount(0);
    }
  }, [package05Amount, package1Amount, package5Amount]);

  const handleProductionDateChange = (date) => {
    setPackingDate(date, 1);
  };

  const maxPackageSizeAmount = (packageSize) => {
    const selectedProduct = warehouseMint.find(
      (product) => product._id === mintID
    );
    const maxProductAmount =
      selectedProduct.amount.produced - selectedProduct.amount.used;
    const avaliablePackageAmount = Math.floor(
      (maxProductAmount -
        (packageSize === 0.5 ? 0 : package1Amount * 0.5) -
        (packageSize === 1 ? 0 : package1Amount * 1) -
        (packageSize === 5 ? 0 : package5Amount * 5)) /
        packageSize
    );
    return avaliablePackageAmount;
  };

  const handlePackage05AmountChange = (input) => {
    const selectedPackage05 = warehousePackage05.find(
      (product) => product._id === package05ID
    );

    const maxPackageAmount05 =
      selectedPackage05.amount.produced - selectedPackage05.amount.used;

    const packageAmount = maxPackageSizeAmount(0.5);

    if (input > maxPackageAmount05) {
      if (maxPackageAmount05 > packageAmount) {
        setPackage05Amount(packageAmount);
      } else {
        setPackage05Amount(maxPackageAmount05);
      }
    } else {
      if (input > packageAmount) {
        setPackage05Amount(packageAmount);
      } else {
        setPackage05Amount(input);
      }
    }
  };

  const handlePackage1AmountChange = (input) => {
    const selectedPackage1 = warehousePackage1.find(
      (product) => product._id === package1ID
    );

    const maxPackageAmount1 =
      selectedPackage1.amount.produced - selectedPackage1.amount.used;

    const packageAmount = maxPackageSizeAmount(1);

    if (input > maxPackageAmount1) {
      if (maxPackageAmount1 > packageAmount) {
        setPackage1Amount(packageAmount);
      } else {
        setPackage1Amount(maxPackageAmount1);
      }
    } else {
      if (input > packageAmount) {
        setPackage1Amount(packageAmount);
      } else {
        setPackage1Amount(input);
      }
    }
  };

  const handlePackage5AmountChange = (input) => {
    const selectedPackage5 = warehousePackage5.find(
      (product) => product._id === package5ID
    );

    const maxPackageAmount5 =
      selectedPackage5.amount.produced - selectedPackage5.amount.used;

    const packageAmount = maxPackageSizeAmount(5);

    if (input > maxPackageAmount5) {
      if (maxPackageAmount5 > packageAmount) {
        setPackage5Amount(packageAmount);
      } else {
        setPackage5Amount(maxPackageAmount5);
      }
    } else {
      if (input > packageAmount) {
        setPackage5Amount(packageAmount);
      } else {
        setPackage5Amount(input);
      }
    }
  };

  const handleProductionSubmit = async () => {
    setFormError("");
    setFormLoading(true);
    const produced = [];
    const used = [];
    const producedDate = new Date(packingDate);
    const expiresDate = new Date(packingDate);
    expiresDate.setDate(expiresDate.getDate() + 180);
    if (package05Amount > 0) {
      produced.push({
        product: "66101210fac9b067b8f69776",
        product_type: "Product",
        amount: package05Amount,
      });
      used.push({ product: package05ID, amount: package05Amount });
    }
    if (package1Amount > 0) {
      produced.push({
        product: "66101220fac9b067b8f69777",
        product_type: "Product",
        amount: package1Amount,
      });
      used.push({ product: package1ID, amount: package1Amount });
    }
    if (package5Amount > 0) {
      produced.push({
        product: "6610122cfac9b067b8f69778",
        product_type: "Product",
        amount: package5Amount,
      });
      used.push({ product: package5ID, amount: package5Amount });
    }
    used.push({
      product: mintID,
      amount: mintAmount,
    });
    const request = {
      produced,
      used,
      date: { produced: producedDate, expires: expiresDate },
    };

    const response = await apiRequest.post("/production/add", request);

    if (response.status_code === 200) {
      window.location.href = `/work/view?date=${packingDate}`;
    } else {
      setFormError(response.error);
    }
  };

  return (
    <Section>
      <SectionHead>
        <SectionHeading>Pakuj - Metanica</SectionHeading>
      </SectionHead>
      <SectionContent>
        <Form>
          <FormInput
            type="date"
            icon={"calendar-day"}
            value={packingDate ? packingDate : toDateFormated()}
            placeholder={"Datum Pakovanja"}
            onChange={(e) => {
              handleProductionDateChange(e.target.value);
            }}
          />
          <FormInputHolder>
            {warehouseMint && warehouseMint.length > 0 ? (
              <>
                <FormSelect
                  placeholder={"Metanica"}
                  options={warehouseMintSelect}
                  value={mintID}
                  onChange={(e) => {
                    setMintID(e.target.value);
                  }}
                />

                <FormInputHolder>
                  {warehousePackage1 && warehousePackage1.length > 0 ? (
                    <>
                      <FormInputHolder>
                        {warehousePackage05 && warehousePackage05.length > 0 ? (
                          <>
                            <FormSelect
                              placeholder={"Ambalaža 0.5kg"}
                              options={warehousePackage05Select}
                              value={package05ID}
                              onChange={(e) => {
                                setMintID(e.target.value);
                              }}
                            />
                            <FormInput
                              icon={"scale-balanced"}
                              placeholder={"Količina (KOM)"}
                              type="number"
                              step="1"
                              min={1}
                              value={package05Amount}
                              onChange={(e) => {
                                const input = e.target.value;
                                handlePackage05AmountChange(parseInt(input));
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <FormError>
                              Nedostaje Ambalaža 0.5kg za Pakovanje
                            </FormError>
                            <FormLink to={"/warehouse/add/package"}>
                              Dodaj Ambalažu 0.5kg
                            </FormLink>
                          </>
                        )}
                      </FormInputHolder>
                      <FormSelect
                        placeholder={"Ambalaža 1kg"}
                        options={warehousePackage1Select}
                        value={package1ID}
                        onChange={(e) => {
                          setMintID(e.target.value);
                        }}
                      />
                      <FormInput
                        icon={"scale-balanced"}
                        placeholder={"Količina (KOM)"}
                        type="number"
                        step="1"
                        min={1}
                        value={package1Amount}
                        onChange={(e) => {
                          const input = e.target.value;
                          handlePackage1AmountChange(parseInt(input));
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <FormError>Nedostaje Ambalaža 1kg za Pakovanje</FormError>
                      <FormLink to={"/warehouse/add/package"}>
                        Dodaj Ambalažu 1kg
                      </FormLink>
                    </>
                  )}
                </FormInputHolder>
                <FormInputHolder>
                  {warehousePackage5 && warehousePackage5.length > 0 ? (
                    <>
                      <FormSelect
                        placeholder={"Ambalaža 5kg"}
                        options={warehousePackage5Select}
                        value={package5ID}
                        onChange={(e) => {
                          setMintID(e.target.value);
                        }}
                      />
                      <FormInput
                        icon={"scale-balanced"}
                        placeholder={"Količina (KOM)"}
                        type="number"
                        step="1"
                        min={1}
                        value={package5Amount}
                        onChange={(e) => {
                          const input = e.target.value;
                          handlePackage5AmountChange(parseInt(input));
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <FormError>Nedostaje Ambalaža 5kg za Pakovanje</FormError>
                      <FormLink to={"/warehouse/add/package"}>
                        Dodaj Ambalažu 5kg
                      </FormLink>
                    </>
                  )}
                </FormInputHolder>
              </>
            ) : (
              <>
                <FormError>Nedostaje Metanica za Pakovanje</FormError>
                <FormLink to={"/production/produce/cheese-skimmed-young"}>
                  Proizvedi Metanica
                </FormLink>
              </>
            )}
          </FormInputHolder>
          {mintAmount > 0 && (
            <FormNotice>Spakuj {mintAmount}kg Metanica</FormNotice>
          )}
          {isProduceable && (
            <FormSubmit
              onClick={handleProductionSubmit}
              isLoading={formLoading}
            >
              Pakuj
            </FormSubmit>
          )}
        </Form>
      </SectionContent>
    </Section>
  );
};

export default PackMint;
