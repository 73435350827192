import React from "react";
import CertificatePDF from "./CertificatePDF";
import toDateReadable from "../../../functions/toDateReadable";

import PDFLink from "../PDFLink";

const PrintC = ({
  farmers,
  milks,
  milkFarmPrice,
  milkNoFarmPrice,
  startDate,
  endDate,
  certificateStart,
  milkQualities,
}) => {
  const startDateReadable = toDateReadable(new Date(startDate));
  const endDateReadable = toDateReadable(new Date(endDate));

  const certificateDocument = (
    <CertificatePDF
      farmers={farmers}
      milks={milks}
      milkFarmPrice={milkFarmPrice}
      milkNoFarmPrice={milkNoFarmPrice}
      startDate={startDate}
      endDate={endDate}
      certificateStart={certificateStart}
      milkQualities={milkQualities}
    />
  );

  return (
    <div className="flex flex-wrap items-center gap-2 mt-4">
      <PDFLink
        document={certificateDocument}
        fileName={`Mleko - Priznanice (${startDateReadable} - ${endDateReadable}).pdf`}
        label="Priznanice"
      />
    </div>
  );
};

export default PrintC;
