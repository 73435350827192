import React from "react";

const FormError = ({ children }) => {
  return (
    <div className="bg-red-500 font-bold text-white p-4 rounded-lg text-center xl:text-lg">
      {children}
    </div>
  );
};

export default FormError;
