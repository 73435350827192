import React, { useEffect, useState } from "react";
import Section from "../../components/section/Section";
import SectionHead from "../../components/section/SectionHead";
import SectionContent from "../../components/section/SectionContent";
import Form from "../../components/form/Form";
import FormInput from "../../components/form/FormInput";
import FormSelect from "../../components/form/FormSelect";
import FormSubmit from "../../components/form/FormSubmit";
import apiRequest from "../../api/apiRequest";
import FormError from "../../components/form/FormError";
import SectionHeading from "../../components/typography/SectionHeading";
import toDateFormated from "../../functions/toDateFormated";
import toBusinessSelect from "../../functions/toBusinessSelect";
import useBusiness from "../../hooks/business/useBusiness";
import LoadingData from "../../components/data/LoadingData";
import FormInputHolder from "../../components/form/FormInputHolder";
import FormNotice from "../../components/form/FormNotice";
import FormLink from "../../components/form/FormLink";

const BusinessEdit = () => {
  const [formLoading, setLoadingData] = useState(false);
  const [formError, setFormError] = useState();

  const [businessDate, setBusinessDate] = useState(toDateFormated());
  const [businessID, setBusinessID] = useState();
  const [businessAmount, setBusinessAmount] = useState();

  const { business, businessLoading, businessError, businessRefetch } =
    useBusiness(`date_start=${businessDate}&date_end=${businessDate}`);

  const businessSelect = toBusinessSelect(business);

  useEffect(() => {
    if (business && business.length > 0) {
      setBusinessID(business[0]._id);
    }
  }, [business]);

  useEffect(() => {
    businessRefetch();
  }, [businessDate]);

  const handleFormSubmit = async () => {
    setLoadingData(true);
    setFormError(false);
    const request = {
      id: businessID,
      amount: businessAmount,
    };
    const response = await apiRequest.post("/business/edit", request);
    setLoadingData(false);
    if (response.status_code === 200) {
      window.location.reload();
    } else {
      setFormError(response.error);
    }
  };

  const handleFormChange = () => {
    setFormError(null);
  };
  return (
    <Section>
      <SectionHead>
        <SectionHeading>Poslovanje - Uredi</SectionHeading>
      </SectionHead>
      <SectionContent>
        {businessLoading ? (
          <LoadingData />
        ) : (
          <Form>
            <FormInput
              type="date"
              icon={"calendar-day"}
              value={businessDate}
              placeholder={"Datum"}
              onChange={(e) => {
                handleFormChange();
                setBusinessDate(e.target.value);
              }}
            />

            {business && business.length > 0 ? (
              <>
                <FormInputHolder>
                  <FormSelect
                    placeholder={"Poslovanje"}
                    options={businessSelect}
                    onChange={(e) => {
                      handleFormChange();
                      setBusinessID(e.target.value);
                    }}
                  />
                  <FormInput
                    icon={"scale-balanced"}
                    value={businessAmount}
                    placeholder={"Novi Iznos"}
                    onChange={(e) => {
                      handleFormChange();
                      setBusinessAmount(e.target.value);
                    }}
                  />
                </FormInputHolder>
                {formError ? (
                  <FormError>{formError}</FormError>
                ) : (
                  <FormSubmit
                    onClick={() => handleFormSubmit()}
                    isLoading={formLoading}
                  >
                    Uredi
                  </FormSubmit>
                )}
              </>
            ) : (
              <>
                <FormNotice>Nema Poslovanja za Uređivanje</FormNotice>
                <FormLink to={"/business/add"}>Dodaj Poslovanje</FormLink>
              </>
            )}
          </Form>
        )}
      </SectionContent>
    </Section>
  );
};

export default BusinessEdit;
