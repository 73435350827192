import React, { useEffect, useState } from "react";
import Section from "../../../components/section/Section";
import SectionHead from "../../../components/section/SectionHead";
import SectionHeading from "../../../components/typography/SectionHeading";
import SectionContent from "../../../components/section/SectionContent";
import FormInput from "../../../components/form/FormInput";
import toDateFormated from "../../../functions/toDateFormated";
import useCookie from "../../../hooks/useCookie";
import Form from "../../../components/form/Form";
import FormInputHolder from "../../../components/form/FormInputHolder";
import FormSelect from "../../../components/form/FormSelect";
import useWarehouseProduct from "../../../hooks/useWarehouseProduct";
import useProductSelect from "../../../hooks/useProductSelect";
import FormError from "../../../components/form/FormError";
import FormLink from "../../../components/form/FormLink";
import FormSubmit from "../../../components/form/FormSubmit";
import apiRequest from "../../../api/apiRequest";
import FormNotice from "../../../components/form/FormNotice";

const PackCreamPepperCutted = () => {
  const [isProduceable, setIsProduceable] = useState(false);
  const [packingDate, setPackingDate] = useCookie("packing-date");
  const [formLoading, setFormLoading] = useState(false);
  const [formError, setFormError] = useState();

  const [creamPepperCuttedID, setCreamPepperCuttedID] = useState();
  const [creamPepperCuttedAmount, setCreamPepperCuttedAmount] = useState();

  const [package025ID, setPackage025ID] = useState();
  const [package025Amount, setPackage025Amount] = useState("");

  const [package04ID, setPackage04ID] = useState();
  const [package04Amount, setPackage04Amount] = useState("");

  const [package1ID, setPackage1ID] = useState();
  const [package1Amount, setPackage1Amount] = useState("");

  const [package2ID, setPackage2ID] = useState();
  const [package2Amount, setPackage2Amount] = useState("");

  const {
    warehouseProducts: warehouseCreamPepperCutted,
    warehouseRefetch: warehouseCreamPepperCuttedRefetch,
  } = useWarehouseProduct(
    `product=660978b6ec44f58429269730&amount=unused&date_produced=${packingDate}`
  );
  const warehouseCreamPepperCuttedSelect = useProductSelect(
    warehouseCreamPepperCutted,
    "kg"
  );

  const {
    warehouseProducts: warehousePackage025,
    warehouseRefetch: warehousePackage025Refetch,
  } = useWarehouseProduct(
    `product=660f2fb249a1b746df531abf&amount=unused&date_produced=${packingDate}`
  );
  const warehousePackage025Select = useProductSelect(
    warehousePackage025,
    "kom"
  );

  const {
    warehouseProducts: warehousePackage04,
    warehouseRefetch: warehousePackage04Refetch,
  } = useWarehouseProduct(
    `product=660f2fbb49a1b746df531ac0&amount=unused&date_produced=${packingDate}`
  );
  const warehousePackage04Select = useProductSelect(warehousePackage04, "kom");

  const {
    warehouseProducts: warehousePackage1,
    warehouseRefetch: warehousePackage1Refetch,
  } = useWarehouseProduct(
    `product=660f2fd449a1b746df531ac3&amount=unused&date_produced=${packingDate}`
  );
  const warehousePackage1Select = useProductSelect(warehousePackage1, "kom");

  const {
    warehouseProducts: warehousePackage2,
    warehouseRefetch: warehousePackage2Refetch,
  } = useWarehouseProduct(
    `product=660f2fea49a1b746df531ac5&amount=unused&date_produced=${packingDate}`
  );
  const warehousePackage2Select = useProductSelect(warehousePackage2, "kom");

  useEffect(() => {
    if (warehouseCreamPepperCutted && warehouseCreamPepperCutted.length > 0) {
      const initialWarehouseCreamPepperCutted = warehouseCreamPepperCutted[0];
      const initialWarehouseCreamPepperCuttedID =
        initialWarehouseCreamPepperCutted._id;
      setCreamPepperCuttedID(initialWarehouseCreamPepperCuttedID);
    } else {
      setIsProduceable(false);
    }
  }, [warehouseCreamPepperCutted]);

  useEffect(() => {
    if (warehousePackage025 && warehousePackage025.length > 0) {
      const initialWarehousePackage025 = warehousePackage025[0];
      const initialWarehousePackage025ID = initialWarehousePackage025._id;
      setPackage025ID(initialWarehousePackage025ID);
    }
  }, [warehousePackage025]);

  useEffect(() => {
    if (warehousePackage04 && warehousePackage04.length > 0) {
      const initialWarehousePackage04 = warehousePackage04[0];
      const initialWarehousePackage04ID = initialWarehousePackage04._id;
      setPackage04ID(initialWarehousePackage04ID);
    }
  }, [warehousePackage04]);

  useEffect(() => {
    if (warehousePackage1 && warehousePackage1.length > 0) {
      const initialWarehousePackage1 = warehousePackage1[0];
      const initialWarehousePackage1ID = initialWarehousePackage1._id;
      setPackage1ID(initialWarehousePackage1ID);
    }
  }, [warehousePackage1]);

  useEffect(() => {
    if (warehousePackage2 && warehousePackage2.length > 0) {
      const initialWarehousePackage2 = warehousePackage2[0];
      const initialWarehousePackage2ID = initialWarehousePackage2._id;
      setPackage2ID(initialWarehousePackage2ID);
    }
  }, [warehousePackage2]);

  useEffect(() => {
    warehouseCreamPepperCuttedRefetch();
    warehousePackage1Refetch();
    warehousePackage2Refetch();
    warehousePackage025Refetch();
    warehousePackage04Refetch();
  }, [packingDate]);

  useEffect(() => {
    if (
      package025Amount > 0 ||
      package04Amount > 0 ||
      package1Amount > 0 ||
      package2Amount > 0
    ) {
      setIsProduceable(true);
      setCreamPepperCuttedAmount(
        package025Amount * 0.25 +
          package04Amount * 0.4 +
          package1Amount * 1 +
          package2Amount * 2
      );
    } else {
      setIsProduceable(false);
      setCreamPepperCuttedAmount(0);
    }
  }, [package025Amount, package04Amount, package1Amount, package2Amount]);

  const handleProductionDateChange = (date) => {
    setPackingDate(date, 1);
  };

  const maxPackageSizeAmount = (packageSize) => {
    const selectedProduct = warehouseCreamPepperCutted.find(
      (product) => product._id === creamPepperCuttedID
    );
    const maxProductAmount =
      selectedProduct.amount.produced - selectedProduct.amount.used;
    const avaliablePackageAmount = Math.floor(
      (maxProductAmount -
        (packageSize === 0.25 ? 0 : package025Amount * 0.25) -
        (packageSize === 0.4 ? 0 : package04Amount * 0.4) -
        (packageSize === 1 ? 0 : package1Amount * 1) -
        (packageSize === 2 ? 0 : package2Amount * 2)) /
        packageSize
    );
    return avaliablePackageAmount;
  };

  const handlePackage025AmountChange = (input) => {
    const selectedPackage025 = warehousePackage025.find(
      (product) => product._id === package025ID
    );
    const maxPackageAmount025 =
      selectedPackage025.amount.produced - selectedPackage025.amount.used;

    const packageAmount = maxPackageSizeAmount(0.25);

    if (input > maxPackageAmount025) {
      if (maxPackageAmount025 > packageAmount) {
        setPackage025Amount(packageAmount);
      } else {
        setPackage025Amount(maxPackageAmount025);
      }
    } else {
      if (input > packageAmount) {
        setPackage025Amount(packageAmount);
      } else {
        setPackage025Amount(input);
      }
    }
  };

  const handlePackage04AmountChange = (input) => {
    const selectedPackage04 = warehousePackage04.find(
      (product) => product._id === package04ID
    );
    const maxPackageAmount04 =
      selectedPackage04.amount.produced - selectedPackage04.amount.used;

    const packageAmount = maxPackageSizeAmount(0.4);

    if (input > maxPackageAmount04) {
      if (maxPackageAmount04 > packageAmount) {
        setPackage04Amount(packageAmount);
      } else {
        setPackage04Amount(maxPackageAmount04);
      }
    } else {
      if (input > packageAmount) {
        setPackage04Amount(packageAmount);
      } else {
        setPackage04Amount(input);
      }
    }
  };

  const handlePackage1AmountChange = (input) => {
    const selectedPackage1 = warehousePackage1.find(
      (product) => product._id === package1ID
    );
    const maxPackageAmount1 =
      selectedPackage1.amount.produced - selectedPackage1.amount.used;

    const packageAmount = maxPackageSizeAmount(1);

    if (input > maxPackageAmount1) {
      if (maxPackageAmount1 > packageAmount) {
        setPackage1Amount(packageAmount);
      } else {
        setPackage1Amount(maxPackageAmount1);
      }
    } else {
      if (input > packageAmount) {
        setPackage1Amount(packageAmount);
      } else {
        setPackage1Amount(input);
      }
    }
  };

  const handlePackage2AmountChange = (input) => {
    const selectedPackage2 = warehousePackage2.find(
      (product) => product._id === package2ID
    );
    const maxPackageAmount2 =
      selectedPackage2.amount.produced - selectedPackage2.amount.used;

    const packageAmount = maxPackageSizeAmount(2);

    if (input > maxPackageAmount2) {
      if (maxPackageAmount2 > packageAmount) {
        setPackage2Amount(packageAmount);
      } else {
        setPackage2Amount(maxPackageAmount2);
      }
    } else {
      if (input > packageAmount) {
        setPackage2Amount(packageAmount);
      } else {
        setPackage2Amount(input);
      }
    }
  };

  const handleProductionSubmit = async () => {
    setFormError("");
    setFormLoading(true);
    const produced = [];
    const used = [];
    const producedDate = new Date(packingDate);
    const expiresDate = new Date(packingDate);
    expiresDate.setDate(expiresDate.getDate() + 90);
    if (package025Amount > 0) {
      produced.push({
        product: "66101349fac9b067b8f69783",
        product_type: "Product",
        amount: package025Amount,
      });
      used.push({ product: package025ID, amount: package025Amount });
    }
    if (package04Amount > 0) {
      produced.push({
        product: "6610135ffac9b067b8f69784",
        product_type: "Product",
        amount: package04Amount,
      });
      used.push({ product: package04ID, amount: package04Amount });
    }
    if (package1Amount > 0) {
      produced.push({
        product: "6610136bfac9b067b8f69785",
        product_type: "Product",
        amount: package1Amount,
      });
      used.push({ product: package1ID, amount: package1Amount });
    }
    if (package2Amount > 0) {
      produced.push({
        product: "66101379fac9b067b8f69786",
        product_type: "Product",
        amount: package2Amount,
      });
      used.push({ product: package2ID, amount: package2Amount });
    }
    used.push({
      product: creamPepperCuttedID,
      amount: creamPepperCuttedAmount,
    });
    const request = {
      produced,
      used,
      date: { produced: producedDate, expires: expiresDate },
    };

    const response = await apiRequest.post("/production/add", request);

    if (response.status_code === 200) {
      window.location.href = `/work/view?date=${packingDate}`;
    } else {
      setFormError(response.error);
    }
  };

  return (
    <Section>
      <SectionHead>
        <SectionHeading>Pakuj - Seckana Paprika u Pavlaci</SectionHeading>
      </SectionHead>
      <SectionContent>
        <Form>
          <FormInput
            type="date"
            icon={"calendar-day"}
            value={packingDate ? packingDate : toDateFormated()}
            placeholder={"Datum Pakovanja"}
            onChange={(e) => {
              handleProductionDateChange(e.target.value);
            }}
          />
          <FormInputHolder>
            {warehouseCreamPepperCutted &&
            warehouseCreamPepperCutted.length > 0 ? (
              <>
                <FormSelect
                  placeholder={"Seckana Paprika u Pavlaci"}
                  options={warehouseCreamPepperCuttedSelect}
                  value={creamPepperCuttedID}
                  onChange={(e) => {
                    setCreamPepperCuttedID(e.target.value);
                  }}
                />
                <FormInputHolder>
                  {warehousePackage1 && warehousePackage1.length > 0 ? (
                    <>
                      <FormSelect
                        placeholder={"Ambalaža 1kg"}
                        options={warehousePackage1Select}
                        value={package1ID}
                        onChange={(e) => {
                          setCreamPepperCuttedID(e.target.value);
                        }}
                      />
                      <FormInput
                        icon={"scale-balanced"}
                        placeholder={"Količina (KOM)"}
                        type="number"
                        step="1"
                        min={1}
                        value={package1Amount}
                        onChange={(e) => {
                          const input = e.target.value;
                          handlePackage1AmountChange(parseInt(input));
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <FormError>Nedostaje Ambalaža 1kg za Pakovanje</FormError>
                      <FormLink to={"/warehouse/add/package"}>
                        Dodaj Ambalažu 1kg
                      </FormLink>
                    </>
                  )}
                </FormInputHolder>
                <FormInputHolder>
                  {warehousePackage2 && warehousePackage2.length > 0 ? (
                    <>
                      <FormSelect
                        placeholder={"Ambalaža 2kg"}
                        options={warehousePackage2Select}
                        value={package2ID}
                        onChange={(e) => {
                          setCreamPepperCuttedID(e.target.value);
                        }}
                      />
                      <FormInput
                        icon={"scale-balanced"}
                        placeholder={"Količina (KOM)"}
                        type="number"
                        step="1"
                        min={1}
                        value={package2Amount}
                        onChange={(e) => {
                          const input = e.target.value;
                          handlePackage2AmountChange(parseInt(input));
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <FormError>Nedostaje Ambalaža 2kg za Pakovanje</FormError>
                      <FormLink to={"/warehouse/add/package"}>
                        Dodaj Ambalažu 2kg
                      </FormLink>
                    </>
                  )}
                </FormInputHolder>
                <FormInputHolder>
                  {warehousePackage025 && warehousePackage025.length > 0 ? (
                    <>
                      <FormSelect
                        placeholder={"Ambalaža 025kg"}
                        options={warehousePackage025Select}
                        value={package025ID}
                        onChange={(e) => {
                          setCreamPepperCuttedID(e.target.value);
                        }}
                      />
                      <FormInput
                        icon={"scale-balanced"}
                        placeholder={"Količina (KOM)"}
                        type="number"
                        step="1"
                        min={1}
                        value={package025Amount}
                        onChange={(e) => {
                          const input = e.target.value;
                          handlePackage025AmountChange(parseInt(input));
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <FormError>
                        Nedostaje Ambalaža 025kg za Pakovanje
                      </FormError>
                      <FormLink to={"/warehouse/add/package"}>
                        Dodaj Ambalažu 025kg
                      </FormLink>
                    </>
                  )}
                </FormInputHolder>
                <FormInputHolder>
                  {warehousePackage04 && warehousePackage04.length > 0 ? (
                    <>
                      <FormSelect
                        placeholder={"Ambalaža 04kg"}
                        options={warehousePackage04Select}
                        value={package04ID}
                        onChange={(e) => {
                          setCreamPepperCuttedID(e.target.value);
                        }}
                      />
                      <FormInput
                        icon={"scale-balanced"}
                        placeholder={"Količina (KOM)"}
                        type="number"
                        step="1"
                        min={1}
                        value={package04Amount}
                        onChange={(e) => {
                          const input = e.target.value;
                          handlePackage04AmountChange(parseInt(input));
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <FormError>
                        Nedostaje Ambalaža 04kg za Pakovanje
                      </FormError>
                      <FormLink to={"/warehouse/add/package"}>
                        Dodaj Ambalažu 04kg
                      </FormLink>
                    </>
                  )}
                </FormInputHolder>
              </>
            ) : (
              <>
                <FormError>
                  Nedostaje Seckana Paprika u Pavlaci za Pakovanje
                </FormError>
                <FormLink to={"/production/produce/cream-spread"}>
                  Proizvedi Seckana Paprika u Pavlaci
                </FormLink>
              </>
            )}
          </FormInputHolder>
          {creamPepperCuttedAmount > 0 && (
            <FormNotice>
              Spakuj {creamPepperCuttedAmount}kg Seckana Paprika u Pavlaci
            </FormNotice>
          )}
          {isProduceable && (
            <FormSubmit
              onClick={handleProductionSubmit}
              isLoading={formLoading}
            >
              Pakuj
            </FormSubmit>
          )}
        </Form>
      </SectionContent>
    </Section>
  );
};

export default PackCreamPepperCutted;
