import React from "react";
import Section from "../../components/section/Section";
import SectionHeading from "../../components/typography/SectionHeading";
import SectionHead from "../../components/section/SectionHead";
import SectionContent from "../../components/section/SectionContent";
import useProduct from "../../hooks/product/useProduct";
import Icon from "../../components/icon/Icon";
import LoadingData from "../../components/data/LoadingData";
import FormError from "../../components/form/FormError";
import FormLink from "../../components/form/FormLink";
import Form from "../../components/form/Form";
import ViewList from "../../components/list/ViewList";
import ViewItem from "../../components/list/ViewItem";

const ProductView = () => {
  const { products, productsLoading } = useProduct();
  return (
    <Section>
      <SectionHead>
        <SectionHeading>Pregledaj Proizvode</SectionHeading>
      </SectionHead>
      <SectionContent>
        {productsLoading ? (
          <LoadingData />
        ) : (
          <>
            {products && products.length > 0 ? (
              <ViewList>
                {products.map((product, key) => {
                  return (
                    <ViewItem
                      key={key}
                      icon={"boxes-stacked"}
                      heading={
                        product.name +
                        " - " +
                        product.package.amount +
                        product.package.unit
                      }
                    />
                  );
                })}
              </ViewList>
            ) : (
              <Form>
                <FormError>Nema Proizvoda za Pregledavanje</FormError>
                <FormLink to={"/product/add"}>Dodaj Proizvod</FormLink>
              </Form>
            )}
          </>
        )}
      </SectionContent>
    </Section>
  );
};

export default ProductView;
