import React from "react";
import Header from "../header/Header";
import Main from "../main/Main";
import Root from "../root/Root";

const PageLayout = () => {
  return (
    <Root>
      <Header />
      <Main />
    </Root>
  );
};

export default PageLayout;
