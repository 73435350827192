import React, { useEffect, useState } from "react";
import apiRequest from "../../api/apiRequest";

const MilkGet = () => {
  const [milkData, setMilkData] = useState();
  useEffect(() => {
    const getMilk = async () => {
      const response = await apiRequest.get(
        `/milk/get?date_start=2024-04-16&date_end=2024-04-30`
      );
      if (response.status_code === 200) {
        const result = response.result;
        setMilkData(result);
      }
    };
    getMilk();
  }, []);
  return <div>MilkGet</div>;
};

export default MilkGet;
