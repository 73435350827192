import React, { useEffect, useState } from "react";
import Section from "../../components/section/Section";
import SectionHead from "../../components/section/SectionHead";
import SectionContent from "../../components/section/SectionContent";
import Form from "../../components/form/Form";
import FormInput from "../../components/form/FormInput";
import apiRequest from "../../api/apiRequest";
import FormError from "../../components/form/FormError";
import SectionHeading from "../../components/typography/SectionHeading";
import toDateFormated from "../../functions/toDateFormated";
import toDateReadable from "../../functions/toDateReadable";
import useCookie from "../../hooks/useCookie";
import LoadingData from "../../components/data/LoadingData";

const MilkView = () => {
  const [formLoading, setLoadingData] = useState(true);
  const [formError, setFormError] = useState();

  const [milkDateStartCookie, setMilkDateStartCookie] = useCookie(
    "milk-view-date-start"
  );
  const [milkDateEndCookie, setMilkDateEndCookie] =
    useCookie("milk-view-date-end");

  const [milkDateStart, setMilkDateStart] = useState(
    milkDateStartCookie ? milkDateStartCookie : toDateFormated()
  );
  const [milkDateEnd, setMilkDateEnd] = useState(
    milkDateEndCookie
      ? milkDateEndCookie
      : toDateFormated(
          new Date(new Date().getTime() + 16 * 24 * 60 * 60 * 1000)
        )
  );
  const [milkData, setMilkData] = useState();

  const handleMilkDateStartChange = (date) => {
    const isValidDate = new Date(date);
    if (!isNaN(isValidDate)) {
      setMilkDateStart(date);
      setMilkDateStartCookie(date);
    }
  };

  const handleMilkDateEndChange = (date) => {
    const isValidDate = new Date(date);
    if (!isNaN(isValidDate)) {
      setMilkDateEnd(date);
      setMilkDateEndCookie(date);
    }
  };

  useEffect(() => {
    const getMilk = async () => {
      setLoadingData(true);
      setFormError(false);
      const response = await apiRequest.get(
        `/milk/get?date_start=${milkDateStart}&date_end=${milkDateEnd}`
      );
      setLoadingData(false);
      if (response.status_code === 200) {
        const result = response.result;
        setMilkData(result);
      } else {
        setFormError(response.error);
      }
    };
    getMilk();
  }, [milkDateStart, milkDateEnd]);

  const [totalAmounts, setTotalAmounts] = useState([]);

  useEffect(() => {
    if (milkData) {
      const milksGraph = [];
      milkData.forEach((milk) => {
        const farm = milk.farm;
        const amount = parseFloat(milk.amount);
        const date = toDateReadable(new Date(milk.date));
        const existingIndex = milksGraph.findIndex(
          (entry) => entry.date === date
        );
        if (existingIndex !== -1) {
          milksGraph[existingIndex].total += amount;
          if (farm) {
            milksGraph[existingIndex].farm += amount;
          }
        } else {
          if (farm) {
            milksGraph.push({ date, total: amount, farm: amount });
          } else {
            milksGraph.push({ date, total: amount, farm: 0 });
          }
        }
      });

      setTotalAmounts(milksGraph);
    }
  }, [milkData]);

  return (
    <Section>
      <SectionHead>
        <SectionHeading>Mleko - Pregledaj</SectionHeading>
      </SectionHead>
      <SectionContent>
        <Form>
          <FormInput
            type="date"
            icon={"calendar-day"}
            value={milkDateStart}
            placeholder={"Početni Datum"}
            onChange={(e) => {
              handleMilkDateStartChange(e.target.value);
            }}
          />
          <FormInput
            type="date"
            icon={"calendar-day"}
            value={milkDateEnd}
            placeholder={"Završni Datum"}
            onChange={(e) => {
              handleMilkDateEndChange(e.target.value);
            }}
          />
          {formError && <FormError>{formError}</FormError>}
          {formLoading && <LoadingData />}
        </Form>
        {!formLoading && totalAmounts && totalAmounts.length > 0 && (
          <>
            <div className="overflow-x-auto flex px-4 py-6 my-6 w-full">
              <ul className="inline-flex gap-4 mx-auto">
                {totalAmounts.map((milk, key) => {
                  const milkTotal = milk.total;
                  const milkFarm = milk.farm;
                  const milkDate = milk.date;
                  return (
                    <li key={milkDate} className="flex flex-col">
                      <div>
                        <div className="bg-slate-700 font-bold flex h-10 items-center justify-center rounded-t-lg text-theme-color--primaryContrast sm:text-lg xl:h-16">
                          {milkTotal - milkFarm} L
                        </div>
                        <div className="bg-slate-900 font-bold flex h-10 items-center justify-center text-theme-color--primaryContrast sm:text-lg xl:h-16">
                          {milkFarm} L
                        </div>
                      </div>
                      <div className="bg-theme-color--primary font-bold flex h-20 items-center justify-center rounded-b-lg text-theme-color--primaryContrast sm:text-lg xl:h-32">
                        {milkTotal} L
                      </div>
                      <div className="font-medium mt-2 px-4 sm:text-lg">
                        {milkDate}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="flex items-center gap-2">
              <div className="bg-theme-color--primary font-bold flex-1 flex items-center justify-center p-2 rounded text-theme-color--primaryContrast xl:p-4">
                Ukupno
              </div>
              <div className="bg-slate-900 font-bold flex-1 flex items-center justify-center p-2 rounded text-theme-color--primaryContrast xl:p-4">
                Sa PG
              </div>
              <div className="bg-slate-700 font-bold flex-1 flex items-center justify-center p-2 rounded text-theme-color--primaryContrast xl:p-4">
                Bez PG
              </div>
            </div>
          </>
        )}
      </SectionContent>
    </Section>
  );
};

export default MilkView;
