import React from "react";
import Icon from "../icon/Icon";

const ListItemIcon = ({ icon }) => {
  return (
    <div className="text-lg sm:text-2xl sm:text-3xl">
      <Icon icon={icon} />
    </div>
  );
};

export default ListItemIcon;
