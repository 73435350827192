import { createAsyncThunk } from "@reduxjs/toolkit";
import apiRequest from "../../api/apiRequest";

const userLogInThunk = createAsyncThunk(
  "user/login",
  async (userCredentials) => {
    const request = await apiRequest.post("/user/login", userCredentials);
    const response = await request.data;
    localStorage.setItem("user", JSON.stringify(response));
    return response;
  }
);

export default userLogInThunk;
