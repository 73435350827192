const toProductSelect = (products) => {
  const productSelect =
    products &&
    products.map((product) => {
      return {
        placeholder:
          product.name +
          " - " +
          product.package.amount +
          product.package.unit +
          " (" +
          product.package.name +
          ")",
        value: product._id,
      };
    });
  return productSelect;
};

export default toProductSelect;
