import React from "react";
import logo from "../../../assets/media/logo.svg";
import { Link } from "react-router-dom";

const NavLogo = () => {
  return (
    <li className="flex items-center justify-center xl:bg-theme-color--header xl:p-4 xl:w-full">
      <div>
        <Link to={"/"}>
          <img className="h-12" alt={"Eco Milk Pešter Logo"} src={logo} />
        </Link>
      </div>
    </li>
  );
};

export default NavLogo;
