import React, { useEffect, useState } from "react";
import Section from "../../../components/section/Section";
import SectionHead from "../../../components/section/SectionHead";
import SectionContent from "../../../components/section/SectionContent";
import Form from "../../../components/form/Form";
import FormInput from "../../../components/form/FormInput";
import FormSubmit from "../../../components/form/FormSubmit";
import apiRequest from "../../../api/apiRequest";
import FormError from "../../../components/form/FormError";
import FormSelect from "../../../components/form/FormSelect";
import SectionHeading from "../../../components/typography/SectionHeading";
import useMaterial from "../../../hooks/material/useMaterial";
import toMaterialSelect from "../../../functions/toMaterialSelect";
import LoadingData from "../../../components/data/LoadingData";
import FormLink from "../../../components/form/FormLink";

const WarehouseAddProductMaterial = () => {
  const [formLoading, setLoadingData] = useState(false);
  const [formError, setFormError] = useState();

  const [productMaterialID, setProductMaterialID] = useState();
  const [productMaterialDateArrived, setProductMaterialDateArrived] =
    useState();
  const [productMaterialAmount, setProductMaterialAmount] = useState();

  const { productMaterials, productMaterialsLoading } = useMaterial();
  const productMaterialSelect = toMaterialSelect(productMaterials);

  const handleProductMaterialChange = (productMaterial) => {
    setProductMaterialID(productMaterial._id);
  };

  useEffect(() => {
    if (productMaterials && productMaterials.length > 0) {
      const initialProductMaterial = productMaterials[0];
      handleProductMaterialChange(initialProductMaterial);
    }
  }, [productMaterials]);

  useEffect(() => {
    if (productMaterialID) {
      const selectedProduct = productMaterials.find(
        (productMaterial) => productMaterial._id === productMaterialID
      );
      handleProductMaterialChange(selectedProduct);
    }
  }, [productMaterialID]);

  const handleFormSubmit = async () => {
    setLoadingData(true);
    setFormError(false);
    const request = {
      product: productMaterialID,
      product_type: "ProductMaterial",
      amount: { produced: productMaterialAmount },
      date: { produced: new Date(productMaterialDateArrived) },
    };
    const response = await apiRequest.post("/warehouse/add", request);
    if (response.status_code === 200) {
      window.location.href = "/warehouse/view";
    } else {
      setFormError(response.error);
      setLoadingData(false);
    }
  };

  const handleFormChange = () => {
    setFormError(null);
  };
  return (
    <Section>
      <SectionHead>
        <SectionHeading>Dodaj Materijal u Magacin</SectionHeading>
      </SectionHead>
      <SectionContent>
        {productMaterialsLoading ? (
          <LoadingData />
        ) : (
          <Form>
            {productMaterials && productMaterials.length > 0 ? (
              <>
                <FormSelect
                  placeholder={"Materijal"}
                  options={productMaterialSelect}
                  onChange={(e) => {
                    handleFormChange();
                    setProductMaterialID(e.target.value);
                  }}
                />
                <FormInput
                  type="date"
                  icon={"calendar-day"}
                  placeholder={"Veza Prijema"}
                  onChange={(e) => {
                    handleFormChange();
                    setProductMaterialDateArrived(e.target.value);
                  }}
                />
                <FormInput
                  icon={"scale-balanced"}
                  placeholder={"Količina"}
                  onChange={(e) => {
                    handleFormChange();
                    setProductMaterialAmount(e.target.value);
                  }}
                />
                {formError && <FormError>{formError}</FormError>}
                <FormSubmit
                  onClick={() => handleFormSubmit()}
                  isLoading={formLoading}
                >
                  Dodaj
                </FormSubmit>
              </>
            ) : (
              <>
                <FormError>Nema Materijala za Dodavanje u Magacin</FormError>
                <FormLink to={"/material/add"}>Dodaj Materijal</FormLink>
              </>
            )}
          </Form>
        )}
      </SectionContent>
    </Section>
  );
};

export default WarehouseAddProductMaterial;
