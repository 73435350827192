import React from "react";
import Section from "../../components/section/Section";
import SectionNaviagtionList from "../../components/section/SectionNaviagtionList";
import SectionHeading from "../../components/typography/SectionHeading";
import SectionHead from "../../components/section/SectionHead";
import SectionContent from "../../components/section/SectionContent";

const Print = () => {
  return (
    <Section>
      <SectionHead>
        <SectionHeading>Štampaj</SectionHeading>
      </SectionHead>
      <SectionContent>
        <SectionNaviagtionList
          items={[
            { to: "milk", icon: "droplet", title: "Mleko" },
            { to: "pricelist", icon: "euro-sign", title: "Cenovnik" },
          ]}
        />
      </SectionContent>
    </Section>
  );
};

export default Print;
