import toDateLot from "../functions/toDateLot";

const useProductSelect = (products, unit) => {
  if (products && products.length > 0) {
    const productSelect =
      products &&
      products.map((product) => {
        return {
          placeholder:
            toDateLot(new Date(product.date.produced)) +
            " - " +
            (product.amount.produced - product.amount.used).toFixed(2) +
            unit,
          value: product._id,
        };
      });
    return productSelect;
  }
  return;
};

export default useProductSelect;
