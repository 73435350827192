import React from "react";
import Section from "../../components/section/Section";
import SectionHeading from "../../components/typography/SectionHeading";
import SectionHead from "../../components/section/SectionHead";
import SectionContent from "../../components/section/SectionContent";
import useWarehouse from "../../hooks/warehouse/useWarehouse";
import LoadingData from "../../components/data/LoadingData";
import FormError from "../../components/form/FormError";
import FormLink from "../../components/form/FormLink";
import Form from "../../components/form/Form";
import ViewItem from "../../components/list/ViewItem";
import ViewList from "../../components/list/ViewList";
import Icon from "../../components/icon/Icon";
import toDateReadable from "../../functions/toDateReadable";
import toFixedNumber from "../../functions/toFixedNumber";
import toDateLot from "../../functions/toDateLot";

const WarehouseView = () => {
  const { warehouseProducts, warehouseProductsLoading } =
    useWarehouse("&amount=unused");
  return (
    <Section>
      <SectionHead>
        <SectionHeading>Pregledaj Magacin</SectionHeading>
      </SectionHead>
      <SectionContent>
        {warehouseProductsLoading ? (
          <LoadingData />
        ) : (
          <>
            {warehouseProducts && warehouseProducts.length > 0 ? (
              <ul className="grid gap-4 grid-cols-2 md:grid-cols-3">
                {warehouseProducts.map((warehouseProduct, key) => {
                  let icon;
                  let heading;

                  const warehouseProductType = warehouseProduct.product_type;
                  const warehouseProductAmount =
                    warehouseProduct.amount.produced -
                    warehouseProduct.amount.used;
                  let warehouseProductUnit = warehouseProduct.product.unit;
                  const warehouseProductLOT = toDateLot(
                    new Date(warehouseProduct.date.produced)
                  );
                  if (warehouseProductType === "ProductPackage") {
                    icon = "bucket";
                    heading =
                      warehouseProduct.product.name +
                      " (" +
                      warehouseProduct.product.amount +
                      warehouseProductUnit +
                      ")";
                    warehouseProductUnit = "kom";
                  } else if (warehouseProductType === "ProductMaterial") {
                    icon = "bag-seedling";
                    heading = warehouseProduct.product.name;
                  } else if (warehouseProductType === "ProductRaw") {
                    icon = "droplet";
                    heading = warehouseProduct.product.name;
                  } else if (warehouseProductType === "Product") {
                    icon = "box";
                    heading =
                      warehouseProduct.product.name +
                      " (" +
                      warehouseProduct.product.package.amount +
                      warehouseProduct.product.package.unit +
                      ")";
                  }
                  return (
                    <ViewItem key={key}>
                      <div className="text-lg sm:text-2xl sm:text-3xl">
                        <Icon icon={icon} />
                      </div>
                      <div className="flex flex-col gap-2 items-center justify-center">
                        <div className="flex flex-col items-center justify-center">
                          <h2 className="font-medium">{heading}</h2>
                          <h4>
                            LOT:{" "}
                            <span className="font-medium">
                              {warehouseProductLOT}
                            </span>
                          </h4>
                        </div>
                        <h3 className="bg-theme-color--primary px-4 py-2 rounded-lg text-theme-color--primaryContrast">
                          {toFixedNumber(warehouseProductAmount)}{" "}
                          <span className="uppercase">
                            {warehouseProductUnit}
                          </span>
                        </h3>
                      </div>
                    </ViewItem>
                  );
                })}
              </ul>
            ) : (
              <Form>
                <FormError>
                  Nema Proizvoda u Magacinu za Pregledavanje
                </FormError>
              </Form>
            )}
          </>
        )}
      </SectionContent>
    </Section>
  );
};

export default WarehouseView;
