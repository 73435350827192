import React, { useState } from "react";
import Section from "../../components/section/Section";
import SectionHead from "../../components/section/SectionHead";
import SectionContent from "../../components/section/SectionContent";
import Form from "../../components/form/Form";
import FormInput from "../../components/form/FormInput";
import FormSubmit from "../../components/form/FormSubmit";
import apiRequest from "../../api/apiRequest";
import FormError from "../../components/form/FormError";
import SectionHeading from "../../components/typography/SectionHeading";

const FarmerAdd = () => {
  const [formLoading, setLoadingData] = useState(false);
  const [formError, setFormError] = useState();

  const [farmerFirstName, setFarmerFirstName] = useState();
  const [farmerLastName, setFarmerLastName] = useState();
  const [farmerCity, setFarmerCity] = useState();
  const [farmerStreet, setFarmerStreet] = useState();
  const [farmerStreetNumber, setFarmerStreetNumber] = useState();
  const [farmerPhoneNumber, setFarmerPhoneNumber] = useState();
  const [farmerUIN, setFarmerUIN] = useState();
  const [farmerFarmID, setFarmerFarmID] = useState();
  const [farmerFarmCows, setFarmerFarmCows] = useState();
  const [farmerBankNumber, setFarmerBankNumber] = useState();

  const handleFormSubmit = async () => {
    setLoadingData(true);
    setFormError(false);
    const request = {
      uin: farmerUIN,
      first_name: farmerFirstName,
      last_name: farmerLastName,
      contact: {
        address: {
          city: farmerCity,
          street: farmerStreet,
          street_number: farmerStreetNumber,
        },
        phone_number: farmerPhoneNumber,
      },
      farm: {
        id: farmerFarmID,
        cows: farmerFarmCows,
      },
      bank: {
        checking_number: farmerBankNumber,
      },
    };
    const response = await apiRequest.post("/farmer/add", request);
    setLoadingData(false);
    if (response.status_code === 200) {
      window.location.href = "/farmer/view";
    } else {
      setFormError(response.error);
    }
  };

  const handleFormChange = () => {
    setFormError(null);
  };
  return (
    <Section>
      <SectionHead>
        <SectionHeading>Dodaj Farmera</SectionHeading>
      </SectionHead>
      <SectionContent>
        <Form>
          <FormInput
            icon={"user"}
            placeholder={"Ime"}
            onChange={(e) => {
              handleFormChange();
              setFarmerFirstName(e.target.value);
            }}
          />
          <FormInput
            icon={"user"}
            placeholder={"Prezime"}
            onChange={(e) => {
              handleFormChange();
              setFarmerLastName(e.target.value);
            }}
          />
          <FormInput
            icon={"city"}
            placeholder={"Grad"}
            onChange={(e) => {
              handleFormChange();
              setFarmerCity(e.target.value);
            }}
          />
          <FormInput
            icon={"road"}
            placeholder={"Ulica"}
            onChange={(e) => {
              handleFormChange();
              setFarmerStreet(e.target.value);
            }}
          />
          <FormInput
            icon={"hashtag"}
            placeholder={"Broj ulice"}
            onChange={(e) => {
              handleFormChange();
              setFarmerStreetNumber(e.target.value);
            }}
          />
          <FormInput
            icon={"phone"}
            placeholder={"Broj Telefona"}
            onChange={(e) => {
              handleFormChange();
              setFarmerPhoneNumber(e.target.value);
            }}
          />
          <FormInput
            icon={"id-card"}
            placeholder={"JMBG"}
            onChange={(e) => {
              handleFormChange();
              setFarmerUIN(e.target.value);
            }}
          />
          <FormInput
            icon={"farm"}
            placeholder={"Broj Gazdinstva"}
            onChange={(e) => {
              handleFormChange();
              setFarmerFarmID(e.target.value);
            }}
          />
          <FormInput
            type="number"
            icon={"cow"}
            placeholder={"Broj Krava"}
            onChange={(e) => {
              handleFormChange();
              setFarmerFarmCows(e.target.value);
            }}
          />
          <FormInput
            icon={"building-columns"}
            placeholder={"Broj Tekućeg Računa"}
            onChange={(e) => {
              handleFormChange();
              setFarmerBankNumber(e.target.value);
            }}
          />

          {formError && <FormError>{formError}</FormError>}
          <FormSubmit
            onClick={() => handleFormSubmit()}
            isLoading={formLoading}
          >
            Dodaj
          </FormSubmit>
        </Form>
      </SectionContent>
    </Section>
  );
};

export default FarmerAdd;
