import React from "react";

const toDateFormattedDayMonth = (dateStr) => {
  const date = new Date(dateStr);

  const day = date.getDate();
  const month = date.getMonth() + 1;

  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDate = `${formattedDay}.${formattedMonth}`;

  return formattedDate;
};

export default toDateFormattedDayMonth;
