import React from "react";
import Section from "../../components/section/Section";
import SectionNaviagtionList from "../../components/section/SectionNaviagtionList";
import SectionHeading from "../../components/typography/SectionHeading";
import SectionHead from "../../components/section/SectionHead";
import SectionContent from "../../components/section/SectionContent";

const ProductionProduce = () => {
  return (
    <Section>
      <SectionHead>
        <SectionHeading>Proizvedi</SectionHeading>
      </SectionHead>
      <SectionContent>
        <SectionNaviagtionList
          items={[
            {
              to: "cream-40",
              icon: "bowl-soft-serve",
              title: "Pavlaka Poluproizvod 40%",
            },
            {
              to: "cream-70",
              icon: "bowl-soft-serve",
              title: "Pavlaka Poluproizvod 70%",
            },
            {
              to: "cream-spread",
              icon: "bowl-soft-serve",
              title: "Kajmak Namaz",
            },
            {
              to: "cheese-skimmed-young",
              icon: "cheese",
              title: "Obrani Mladi Sir",
            },
            {
              to: "cheese-skimmed-old",
              icon: "cheese-swiss",
              title: "Obrani Zreli Sir",
            },
            {
              to: "cheese-fatfull-young",
              icon: "cheese",
              title: "Punomasni Mladi Sir",
            },
            {
              to: "cheese-fatfull-old",
              icon: "cheese-swiss",
              title: "Punomsani Zreli Sir",
            },
            {
              to: "butter",
              icon: "bowl-scoop",
              title: "Maslac",
            },
            {
              to: "mint",
              icon: "bowl-scoop",
              title: "Metanica",
            },
            {
              to: "cheese-fresh",
              icon: "cheese",
              title: "Hurda / Sveži Sir",
            },
            {
              to: "whey",
              icon: "bottle-water",
              title: "Surutka",
            },
            {
              to: "cream",
              icon: "bowl-soft-serve",
              title: "Pavlaka Mlečni Namaz",
            },
            {
              to: "cream-pepper",
              icon: "bowl-soft-serve",
              title: "Paprika u Pavlaci",
            },
            {
              to: "cream-pepper-cutted",
              icon: "bowl-soft-serve",
              title: "Seckana Paprika u Pavlaci",
            },
          ]}
        />
      </SectionContent>
    </Section>
  );
};

export default ProductionProduce;
