import React from "react";
import { Link } from "react-router-dom";

const NavProfile = ({ to, name, type }) => {
  return (
    <li className="flex-1 group">
      <Link to={to}>
        <div className="bg-theme-color--headerMenuItem border border-theme-color--headerMenuItemBorder flex gap-x-2 items-center p-2 rounded-lg group-hover:bg-theme-color--primary group-hover:text-theme-color--primaryContrast">
          <img
            className="h-12 w-12"
            alt=""
            src="https://ps.w.org/user-avatar-reloaded/assets/icon-256x256.png?rev=2540745"
          />
          <div className="flex flex-col">
            <h1 className="font-bold">{name}</h1>
            <span className="capitalize font-medium text-xs">{type}</span>
          </div>
        </div>
      </Link>
    </li>
  );
};

export default NavProfile;
