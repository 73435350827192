import { createSlice } from "@reduxjs/toolkit";
import userLogInThunk from "../thunk/userLogInThunk";
import userAuthThunk from "../thunk/userAuthThunk";

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    logIn: {
      loading: false,
      error: null,
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userAuthThunk.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(userLogInThunk.pending, (state) => {
        state.logIn.loading = true;
        state.user = null;
        state.logIn.error = null;
      })
      .addCase(userLogInThunk.fulfilled, (state, action) => {
        state.logIn.loading = false;
        state.user = action.payload;
        state.logIn.error = null;
        window.location.reload();
      })
      .addCase(userLogInThunk.rejected, (state, action) => {
        state.logIn.loading = false;
        state.user = null;
        state.logIn.error = action.error.message;
      });
  },
});

export default userSlice.reducer;
