import React, { useEffect, useState } from "react";
import FormInputHolder from "../../../components/form/FormInputHolder";
import FormSelect from "../../../components/form/FormSelect";
import FormInput from "../../../components/form/FormInput";
import FormSubmit from "../../../components/form/FormSubmit";
import Section from "../../../components/section/Section";
import Form from "../../../components/form/Form";
import useProductSelect from "../../../hooks/useProductSelect";
import FormNotice from "../../../components/form/FormNotice";
import SectionHeading from "../../../components/typography/SectionHeading";
import toFixedNumber from "../../../functions/toFixedNumber";
import SectionHead from "../../../components/section/SectionHead";
import SectionContent from "../../../components/section/SectionContent";
import FormError from "../../../components/form/FormError";
import FormLink from "../../../components/form/FormLink";
import useWarehouseProduct from "../../../hooks/useWarehouseProduct";
import useCookie from "../../../hooks/useCookie";
import apiRequest from "../../../api/apiRequest";
import toDateFormated from "../../../functions/toDateFormated";

const ProduceCheeseSkimmedYoung = () => {
  const [formLoading, setFormLoading] = useState(false);
  const [formError, setFormError] = useState();
  const [isProduceable, setIsProduceable] = useState(false);
  const [productionDate, setProductionDate] = useCookie("production-date");
  const [producesCheeseSkimmedYoung, setProducesCheeseSkimmedYoung] =
    useState();

  const {
    warehouseProducts: warehouseMilkSkimmed,
    warehouseRefetch: warehouseMilkSkimmedRefetch,
  } = useWarehouseProduct(
    `product=6609f649da936024ef03345b&amount=unused&date_produced=${productionDate}`
  );
  const {
    warehouseProducts: warehouseRennet,
    warehouseRefetch: warehouseRennetRefetch,
  } = useWarehouseProduct(
    `product=6606189e77cdc91ffe340f37&amount=unused&date_arrived=${productionDate}`
  );
  const {
    warehouseProducts: warehouseSalt,
    warehouseRefetch: warehouseSaltRefetch,
  } = useWarehouseProduct(
    `product=660478b366de10b314ad7f17&amount=unused&date_arrived=${productionDate}`
  );

  const warehouseMilkSkimmedSelect = useProductSelect(
    warehouseMilkSkimmed,
    "L"
  );
  const warehouseRennetSelect = useProductSelect(warehouseRennet, "L");
  const warehouseSaltSelect = useProductSelect(warehouseSalt, "KG");

  const [milkSkimmedID, setMilkSkimmedID] = useState();
  const [milkSkimmedAmount, setMilkSkimmedAmount] = useState();

  const [rennetID, setRennetID] = useState();
  const [rennetAmount, setRennetAmount] = useState();

  const [saltID, setSaltID] = useState();
  const [saltAmount, setSaltAmount] = useState();

  useEffect(() => {
    if (warehouseMilkSkimmed && warehouseMilkSkimmed.length > 0) {
      setIsProduceable(true);
      const initialWarehouseCheeseSkimmedYoung = warehouseMilkSkimmed[0];
      const initialWarehouseCheeseSkimmedYoungID =
        initialWarehouseCheeseSkimmedYoung._id;
      setMilkSkimmedID(initialWarehouseCheeseSkimmedYoungID);
    } else {
      setIsProduceable(false);
    }
  }, [warehouseMilkSkimmed]);

  useEffect(() => {
    if (warehouseRennet && warehouseRennet.length > 0) {
      const initialWarehouseRennet = warehouseRennet[0];
      const initialWarehouseRennetID = initialWarehouseRennet._id;
      setRennetID(initialWarehouseRennetID);
    }
  }, [warehouseRennet]);

  useEffect(() => {
    if (warehouseSalt && warehouseSalt.length > 0) {
      const initialWarehouseSalt = warehouseSalt[0];
      const initialWarehouseSaltID = initialWarehouseSalt._id;
      setSaltID(initialWarehouseSaltID);
    }
  }, [warehouseSalt]);

  const handleMilkSkimmedChange = (input) => {
    if (input) {
      const selectedMilkSkimmed = warehouseMilkSkimmed.find(
        (product) => product._id === milkSkimmedID
      );
      const maxMilkSkimmedAmount = toFixedNumber(
        selectedMilkSkimmed.amount.produced - selectedMilkSkimmed.amount.used
      );
      const milkSkimmedAmount =
        input > maxMilkSkimmedAmount ? maxMilkSkimmedAmount : input;

      const rennetAmount = toFixedNumber(milkSkimmedAmount * 0.0013);
      const saltAmount = toFixedNumber(milkSkimmedAmount * 0.006);

      setMilkSkimmedAmount(milkSkimmedAmount);
      setRennetAmount(rennetAmount);
      setSaltAmount(saltAmount);
    } else {
      handleReset();
    }
  };

  const handleRennetChange = (input) => {
    if (input) {
      const selectedRennet = warehouseRennet.find(
        (product) => product._id === rennetID
      );
      const maxRennetAmount = toFixedNumber(
        selectedRennet.amount.produced - selectedRennet.amount.used
      );
      const rennetAmount = input > maxRennetAmount ? maxRennetAmount : input;
      setRennetAmount(rennetAmount);
      setMilkSkimmedAmount(toFixedNumber((rennetAmount * 1000) / 1.3));
      setSaltAmount(toFixedNumber(((rennetAmount * 1000) / 1.3) * 0.006));
    } else {
      handleReset();
    }
  };

  const handleSaltChange = (input) => {
    if (input) {
      const selectedSalt = warehouseSalt.find(
        (product) => product._id === saltID
      );
      const maxSaltAmount = toFixedNumber(
        selectedSalt.amount.produced - selectedSalt.amount.used
      );
      const saltAmount = input > maxSaltAmount ? maxSaltAmount : input;
      setSaltAmount(saltAmount);
      setMilkSkimmedAmount(toFixedNumber((saltAmount * 1000) / 6));
      setRennetAmount(toFixedNumber(((saltAmount * 1000) / 6) * 0.0013));
    } else {
      handleReset();
    }
  };

  useEffect(() => {
    if (
      warehouseMilkSkimmed &&
      milkSkimmedID &&
      milkSkimmedAmount > 0 &&
      warehouseRennet &&
      rennetID &&
      rennetAmount > 0 &&
      warehouseSalt &&
      saltID &&
      saltAmount > 0
    ) {
      setIsProduceable(true);
      const selectedMilkSkimmed = warehouseMilkSkimmed.find(
        (product) => product._id === milkSkimmedID
      );
      const maxMilkSkimmedAmount = toFixedNumber(
        selectedMilkSkimmed.amount.produced - selectedMilkSkimmed.amount.used
      );
      const selectedRennet = warehouseRennet.find(
        (product) => product._id === rennetID
      );
      const maxRennetAmount = toFixedNumber(
        selectedRennet.amount.produced - selectedRennet.amount.used
      );
      const selectedSalt = warehouseSalt.find(
        (product) => product._id === saltID
      );
      const maxSaltAmount = toFixedNumber(
        selectedSalt.amount.produced - selectedSalt.amount.used
      );
      if (milkSkimmedAmount > maxMilkSkimmedAmount) {
        handleMilkSkimmedChange(milkSkimmedAmount);
      } else if (rennetAmount > maxRennetAmount) {
        handleRennetChange(rennetAmount);
      } else if (saltAmount > maxSaltAmount) {
        handleSaltChange(saltAmount);
      }
    } else {
      setIsProduceable(false);
    }
  }, [
    warehouseMilkSkimmed,
    milkSkimmedID,
    milkSkimmedAmount,

    warehouseRennet,
    rennetID,
    rennetAmount,

    warehouseSalt,
    saltID,
    saltAmount,
  ]);

  useEffect(() => {
    if (
      warehouseMilkSkimmed &&
      warehouseMilkSkimmed.length > 0 &&
      milkSkimmedAmount
    ) {
      const produces = toFixedNumber(milkSkimmedAmount / 9.7);
      setProducesCheeseSkimmedYoung(produces);
    }
  }, [warehouseMilkSkimmed, milkSkimmedAmount]);

  useEffect(() => {
    warehouseMilkSkimmedRefetch();
    warehouseRennetRefetch();
    warehouseSaltRefetch();
  }, [productionDate]);

  const handleProductionDateChange = (date) => {
    setProductionDate(date, 1);
  };

  const handleReset = () => {
    setMilkSkimmedAmount("");
    setRennetAmount("");
    setSaltAmount("");
  };

  const handleProductionSubmit = async () => {
    setFormError("");
    setFormLoading(true);
    const request = {
      produced: [
        {
          product: "66097546ec44f58429269725",
          product_type: "ProductRaw",
          amount: producesCheeseSkimmedYoung,
        },
      ],
      used: [
        { product: milkSkimmedID, amount: milkSkimmedAmount },
        { product: rennetID, amount: rennetAmount },
        { product: saltID, amount: saltAmount },
      ],
      date: { produced: new Date(productionDate) },
    };

    const response = await apiRequest.post("/production/add", request);

    if (response.status_code === 200) {
      window.location.reload();
    } else {
      setFormError(response.error);
    }
  };

  return (
    <Section>
      <SectionHead>
        <SectionHeading>Proizvedi - Sir Obrani Mladi</SectionHeading>
      </SectionHead>
      <SectionContent>
        <Form>
          <FormInput
            type="date"
            icon={"calendar-day"}
            value={productionDate ? productionDate : toDateFormated()}
            placeholder={"Datum Proizvodnje"}
            onChange={(e) => {
              handleProductionDateChange(e.target.value);
            }}
          />
          <FormInputHolder>
            {warehouseMilkSkimmed && warehouseMilkSkimmed.length > 0 ? (
              <>
                <FormSelect
                  placeholder={"Mleko"}
                  options={warehouseMilkSkimmedSelect}
                  value={milkSkimmedID}
                  onChange={(e) => {
                    setMilkSkimmedID(e.target.value);
                  }}
                />
                <FormInput
                  icon={"droplet"}
                  placeholder={"Količina (L)"}
                  type="number"
                  step="0.5"
                  min={1}
                  value={milkSkimmedAmount}
                  onChange={(e) => {
                    const input = e.target.value;
                    if (/^\d*\.?\d{0,2}$/.test(input)) {
                      handleMilkSkimmedChange(input);
                    }
                  }}
                />
              </>
            ) : (
              <>
                <FormError>Nedostaje Obrano Mleko za proizvodnju</FormError>
                <div className="flex gap-4">
                  <FormLink to={"/production/produce/cream-40"}>
                    Proizvedi Pavlaku Poluproizvod 40% m.m
                  </FormLink>
                  <FormLink to={"/production/produce/cream-70"}>
                    Proizvedi Pavlaku Poluproizvod 70% m.m
                  </FormLink>
                </div>
              </>
            )}
          </FormInputHolder>

          <FormInputHolder>
            {warehouseRennet && warehouseRennet.length > 0 ? (
              <>
                <FormSelect
                  placeholder={"Sirilo"}
                  options={warehouseRennetSelect}
                  value={rennetID}
                  onChange={(e) => {
                    setRennetID(e.target.value);
                  }}
                />
                <FormInput
                  icon={"scale-balanced"}
                  placeholder={"Količina (L)"}
                  type="number"
                  step="0.01"
                  min={1}
                  value={rennetAmount}
                  onChange={(e) => {
                    const input = e.target.value;
                    if (/^\d*\.?\d{0,2}$/.test(input)) {
                      handleRennetChange(input);
                    }
                  }}
                />
              </>
            ) : (
              <>
                <FormError>Nedostaje Sirilo za proizvodnju</FormError>
                <FormLink to={"/warehouse/add/material"}>Dodaj Sirilo</FormLink>
              </>
            )}
          </FormInputHolder>

          <FormInputHolder>
            {warehouseSalt && warehouseSalt.length > 0 ? (
              <>
                <FormSelect
                  placeholder={"So"}
                  options={warehouseSaltSelect}
                  value={saltID}
                  onChange={(e) => {
                    setSaltID(e.target.value);
                  }}
                />
                <FormInput
                  icon={"scale-balanced"}
                  placeholder={"Količina (KG)"}
                  type="number"
                  step="0.01"
                  min={1}
                  value={saltAmount}
                  onChange={(e) => {
                    const input = e.target.value;
                    if (/^\d*\.?\d{0,2}$/.test(input)) {
                      handleSaltChange(input);
                    }
                  }}
                />
              </>
            ) : (
              <>
                <FormError>Nedostaje So za proizvodnju</FormError>
                <FormLink to={"/warehouse/add/material"}>Dodaj So</FormLink>
              </>
            )}
          </FormInputHolder>

          {producesCheeseSkimmedYoung && (
            <FormInputHolder>
              <FormInput
                icon={"scale-balanced"}
                placeholder={"Proizvedi (KG)"}
                type="number"
                min={1}
                value={producesCheeseSkimmedYoung}
                onChange={(e) => {
                  const input = e.target.value;
                  if (/^\d*\.?\d{0,2}$/.test(input)) {
                    setProducesCheeseSkimmedYoung(input);
                  }
                }}
              />
              <FormNotice>
                Proizvedi {producesCheeseSkimmedYoung}kg Obranog Mladog Sira
              </FormNotice>
            </FormInputHolder>
          )}
          {isProduceable && (
            <FormSubmit
              onClick={handleProductionSubmit}
              isLoading={formLoading}
            >
              Proizvedi
            </FormSubmit>
          )}
        </Form>
      </SectionContent>
    </Section>
  );
};

export default ProduceCheeseSkimmedYoung;
