import React from "react";

const Section = ({ children }) => {
  return (
    <section className="bg-white border overflow-hidden mb-4 rounded-xl shadow w-full">
      {children}
    </section>
  );
};

export default Section;
