import React from "react";
import Section from "../../components/section/Section";
import SectionHeading from "../../components/typography/SectionHeading";
import SectionHead from "../../components/section/SectionHead";
import SectionContent from "../../components/section/SectionContent";
import usePackage from "../../hooks/package/usePackage";
import LoadingData from "../../components/data/LoadingData";
import FormError from "../../components/form/FormError";
import FormLink from "../../components/form/FormLink";
import Form from "../../components/form/Form";
import ViewItem from "../../components/list/ViewItem";
import ViewList from "../../components/list/ViewList";
import Icon from "../../components/icon/Icon";
import ListItemIcon from "../../components/list/ListItemIcon";
import ListItemFlexIconHeading from "../../components/list/ListItemFlexIconHeading";
import ListItemHeading from "../../components/list/ListItemHeading";

const PackageView = () => {
  const { productPackages, productPackagesLoading } = usePackage();
  return (
    <Section>
      <SectionHead>
        <SectionHeading>Pregledaj Ambalaže</SectionHeading>
      </SectionHead>
      <SectionContent>
        {productPackagesLoading ? (
          <LoadingData />
        ) : (
          <>
            {productPackages && productPackages.length > 0 ? (
              <ViewList>
                {productPackages.map((productPackage, key) => {
                  return (
                    <ViewItem key={key}>
                      <ListItemFlexIconHeading>
                        <ListItemIcon icon={"bucket"} />
                        <ListItemHeading>
                          {" "}
                          {productPackage.name +
                            " - " +
                            productPackage.amount +
                            productPackage.unit}
                        </ListItemHeading>
                      </ListItemFlexIconHeading>
                    </ViewItem>
                  );
                })}
              </ViewList>
            ) : (
              <Form>
                <FormError>Nema Ambalaža za Pregledavanje</FormError>
                <FormLink to={"/productPackage/add"}>Dodaj Ambalažu</FormLink>
              </Form>
            )}
          </>
        )}
      </SectionContent>
    </Section>
  );
};

export default PackageView;
