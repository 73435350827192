import { useEffect, useState } from "react";
import apiRequest from "../../api/apiRequest";

const useWarehouseProduct = (query) => {
  const [warehouseProducts, setWarehouseProducts] = useState();
  const [warehouseProductsLoading, setWarehouseProductsLoading] =
    useState(true);
  const [warehouseProductsError, setWarehouseProductsError] = useState(null);

  const getWarehouseProducts = async () => {
    try {
      const response = await apiRequest.get(`/warehouse/get?${query}`);
      if (response.status_code === 200 && response.result) {
        const warehouseProducts = response.result;
        setWarehouseProducts(warehouseProducts);
      } else {
        setWarehouseProductsError("Failed to fetch data");
      }
    } catch (error) {
      setWarehouseProductsError("Failed to fetch data");
    } finally {
      setWarehouseProductsLoading(false);
    }
  };

  useEffect(() => {
    getWarehouseProducts();
  }, []);

  return {
    warehouseProducts,
    warehouseProductsLoading,
    warehouseProductsError,
    warehouseProductsRefetch: getWarehouseProducts,
  }; // Return a promise if needed
};

export default useWarehouseProduct;
