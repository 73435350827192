import React, { useEffect, useRef, useState } from "react";
import Section from "../../components/section/Section";
import Form from "../../components/form/Form";
import FormInput from "../../components/form/FormInput";
import FormSelect from "../../components/form/FormSelect";
import FormSubmit from "../../components/form/FormSubmit";
import apiRequest from "../../api/apiRequest";
import FormError from "../../components/form/FormError";
import SectionHeading from "../../components/typography/SectionHeading";
import toDateFormated from "../../functions/toDateFormated";
import SectionHead from "../../components/section/SectionHead";
import SectionContent from "../../components/section/SectionContent";
import useFarmer from "../../hooks/farmer/useFarmer";
import useMilk from "../../hooks/milk/useMilk";
import FormInputHolder from "../../components/form/FormInputHolder";
import FormNotice from "../../components/form/FormNotice";
import toFarmerSelect from "../../functions/toFarmerSelect";
import FormLink from "../../components/form/FormLink";
import LoadingData from "../../components/data/LoadingData";
import ListItemIcon from "../../components/list/ListItemIcon";
import ViewList from "../../components/list/ViewList";
import ViewItem from "../../components/list/ViewItem";
import ListItemHeading from "../../components/list/ListItemHeading";
import ListItemFlexIconHeading from "../../components/list/ListItemFlexIconHeading";

const MilkAdd = () => {
  const milkAmountRef = useRef();
  const [formLoading, setLoadingData] = useState(false);
  const [formError, setFormError] = useState();

  const [milkDate, setMilkDate] = useState(toDateFormated());
  const [farmerID, setFarmerID] = useState();
  const [milkAmount, setMilkAmount] = useState();
  const [isMilkDateAdded, setIsMilkDateAdded] = useState();

  const { farmers, farmersLoading } = useFarmer("active=true");
  const { milks, milksLoading, milksError, milksRefetch } = useMilk(
    `date_start=${milkDate}&date_end=${milkDate}`
  );

  const [farmersNoMilk, setFarmersNoMilk] = useState();

  useEffect(() => {
    setIsMilkDateAdded(false);
    if (farmers && farmers.length > 0 && milks) {
      const filteredFarmers = farmers.filter(
        (farmer) => !milks.some((milk) => milk.farmer._id === farmer._id)
      );
      if (filteredFarmers.length > 0) {
        const filteredFarmerSelect = toFarmerSelect(filteredFarmers);
        setFarmerID(filteredFarmers[0]._id);
        setFarmersNoMilk(filteredFarmerSelect);
      } else {
        setIsMilkDateAdded(true);
      }
    }
  }, [farmers, milks]);

  useEffect(() => {
    milksRefetch();
  }, [milkDate]);

  const handleFormSubmit = async () => {
    setLoadingData(true);
    setFormError(false);
    const milkAMountInput = milkAmountRef.current;
    const request = {
      farmer: farmerID,
      amount: milkAmount,
      date: milkDate,
    };
    const response = await apiRequest.post("/milk/add", request);
    setLoadingData(false);
    if (response.status_code === 200) {
      milksRefetch();
      farmersNoMilk.shift();
      if (farmersNoMilk && farmersNoMilk.length > 0) {
        setFarmerID(farmersNoMilk[0].value);
        setMilkAmount("");
        if (milkAMountInput) {
          milkAMountInput.focus();
        }
      } else {
        setIsMilkDateAdded(true);
      }
    } else {
      setFormError(response.error);
    }
  };

  return (
    <Section>
      <SectionHead>
        <SectionHeading>Dodaj Mleko</SectionHeading>
      </SectionHead>
      <SectionContent>
        {farmersLoading && milksLoading ? (
          <LoadingData />
        ) : (
          <Form>
            <FormInput
              type="date"
              icon={"calendar-day"}
              value={milkDate}
              placeholder={"Datum Mleka"}
              onChange={(e) => {
                setMilkDate(e.target.value);
              }}
            />
            {!isMilkDateAdded ? (
              <>
                {farmers ? (
                  <>
                    <FormInputHolder>
                      <FormSelect
                        placeholder={"Farmer"}
                        options={farmersNoMilk}
                        onChange={(e) => {
                          setFarmerID(e.target.value);
                        }}
                      />
                      <FormInput
                        refKey={milkAmountRef}
                        type="number"
                        icon={"scale-balanced"}
                        value={milkAmount}
                        placeholder={"Količina Mleka"}
                        onChange={(e) => {
                          const input = e.target.value;
                          if (/^\d*\.?\d{0,2}$/.test(input)) {
                            setMilkAmount(input);
                          }
                        }}
                      />
                    </FormInputHolder>
                    {formError && <FormError>{formError}</FormError>}
                    <FormSubmit
                      onClick={() => handleFormSubmit()}
                      isLoading={formLoading}
                    >
                      Dodaj
                    </FormSubmit>
                  </>
                ) : (
                  <>
                    <FormError>Nema Farmera za dodavanje mleka</FormError>
                    <FormLink to="/farmer/add">Dodaj Farmera</FormLink>
                  </>
                )}
              </>
            ) : (
              <FormNotice>Svo mleko za ovaj datum je dodano</FormNotice>
            )}
            <ul className="flex flex-col-reverse gap-4 xs:grid xs:grid-cols-2 md:grid-cols-3 2xl:grid-cols-4">
              {milks &&
                milks.length > 0 &&
                milks.map((milk) => {
                  return (
                    <ViewItem>
                      <ListItemFlexIconHeading>
                        <ListItemIcon icon={"droplet"} />
                        <ListItemHeading>
                          {milk.farmer.first_name} {milk.farmer.last_name} -{" "}
                          {milk.amount} L
                        </ListItemHeading>
                      </ListItemFlexIconHeading>
                    </ViewItem>
                  );
                })}
            </ul>
          </Form>
        )}
      </SectionContent>
    </Section>
  );
};

export default MilkAdd;
