import React, { useEffect, useState } from "react";
import Section from "../../components/section/Section.js";
import SectionHead from "../../components/section/SectionHead.js";
import SectionContent from "../../components/section/SectionContent.js";
import Form from "../../components/form/Form.js";
import FormInput from "../../components/form/FormInput.js";
import FormSelect from "../../components/form/FormSelect.js";
import FormSubmit from "../../components/form/FormSubmit.js";
import apiRequest from "../../api/apiRequest.js";
import FormError from "../../components/form/FormError.js";
import SectionHeading from "../../components/typography/SectionHeading.js";
import toWarehouseSelect from "../../functions/toWarehouseSelect.js";
import useWarehouse from "../../hooks/warehouse/useWarehouse.js";
import LoadingData from "../../components/data/LoadingData.js";
import useCookie from "../../hooks/useCookie.js";
import toDateFormated from "../../functions/toDateFormated.js";
import useWarehouseProduct from "../../hooks/useWarehouseProduct.js";

const WarehouseSell = () => {
  const [formLoading, setLoadingData] = useState(false);
  const [formError, setFormError] = useState();

  const [warehouseID, setWarehouseID] = useState();
  const [warehouseAmount, setWarehouseAmount] = useState();
  const [saleDate, setSaleDate] = useCookie("sale-date");

  const { warehouseProducts, warehouseProductsLoading, warehouseRefetch } =
    useWarehouseProduct(
      `&product_type=Product&amount=unused&date_produced=${saleDate}`
    );

  const warehouseSelect = toWarehouseSelect(warehouseProducts);

  useEffect(() => {
    if (warehouseProducts && warehouseProducts.length > 0) {
      setWarehouseID(warehouseProducts[0]._id);
    }
  }, [warehouseProducts]);

  useEffect(() => {
    warehouseRefetch();
  }, [saleDate]);

  const handleSaleDateChange = (date) => {
    setSaleDate(date, 1);
  };

  const handleFormSubmit = async () => {
    setLoadingData(true);
    setFormError(false);
    const request = {
      id: warehouseID,
      amount: warehouseAmount,
      date: saleDate,
    };
    const response = await apiRequest.post("/warehouse/sell", request);
    setLoadingData(false);
    if (response.status_code === 200) {
      window.location.reload();
    } else {
      setFormError(response.error);
    }
  };

  const handleWarehouseProductChange = (id) => {
    setWarehouseID(id);
    setWarehouseAmount("");
  };

  const handleWarehouseAmountChange = (input) => {
    const selectedProduct = warehouseProducts.find(
      (product) => product._id === warehouseID
    );
    const maxProductAmount =
      selectedProduct.amount.produced - selectedProduct.amount.used;
    if (input > maxProductAmount) {
      setWarehouseAmount(maxProductAmount);
    } else {
      setWarehouseAmount(input);
    }
  };

  return (
    <Section>
      <SectionHead>
        <SectionHeading>Magacin - Prodaj</SectionHeading>
      </SectionHead>
      <SectionContent>
        {warehouseProductsLoading ? (
          <LoadingData />
        ) : (
          <Form>
            <FormInput
              type="date"
              icon={"calendar-day"}
              value={saleDate ? saleDate : toDateFormated()}
              placeholder={"Datum Prodaje"}
              onChange={(e) => {
                handleSaleDateChange(e.target.value);
              }}
            />
            <FormSelect
              placeholder={"Proizvod"}
              options={warehouseSelect}
              onChange={(e) => {
                handleWarehouseProductChange(e.target.value);
              }}
            />
            <FormInput
              icon={"scale-balanced"}
              type="number"
              value={warehouseAmount}
              placeholder={"Količina"}
              onChange={(e) => {
                handleWarehouseAmountChange(e.target.value);
              }}
            />
            {formError ? (
              <FormError>{formError}</FormError>
            ) : (
              <FormSubmit
                onClick={() => handleFormSubmit()}
                isLoading={formLoading}
              >
                Prodaj
              </FormSubmit>
            )}
          </Form>
        )}
      </SectionContent>
    </Section>
  );
};

export default WarehouseSell;
