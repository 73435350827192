import React from "react";
import Icon from "../icon/Icon";

const FormSubmit = ({ children, isLoading, onClick }) => {
  return (
    <button
      disabled={isLoading}
      onClick={onClick}
      className="bg-theme-color--primary font-medium flex items-center justify-center p-4 rounded-lg text-theme-color--primaryContrast w-full xl:text-lg"
    >
      {isLoading ? (
        <div className="animate-spin">
          <Icon icon={"spinner"} iconStyle="solid" />
        </div>
      ) : (
        children
      )}
    </button>
  );
};

export default FormSubmit;
