import React, { useEffect, useState } from "react";
import Section from "../../components/section/Section";
import SectionHead from "../../components/section/SectionHead";
import SectionContent from "../../components/section/SectionContent";
import Form from "../../components/form/Form";
import FormInput from "../../components/form/FormInput";
import FormSubmit from "../../components/form/FormSubmit";
import apiRequest from "../../api/apiRequest";
import FormError from "../../components/form/FormError";
import FormSelect from "../../components/form/FormSelect";
import SectionHeading from "../../components/typography/SectionHeading";
import useProduct from "../../hooks/product/useProduct";
import toProductSelect from "../../functions/toProductSelect";
import LoadingData from "../../components/data/LoadingData";
import FormLink from "../../components/form/FormLink";
import FormInputHolder from "../../components/form/FormInputHolder";

const ProductEdit = () => {
  const [formLoading, setLoadingData] = useState(false);
  const [formError, setFormError] = useState();

  const [productID, setProductID] = useState();
  const [productName, setProductName] = useState();
  const [productPriceRetail, setProductPriceRetail] = useState();
  const [productPriceWholesale, setProductPriceWholesale] = useState();
  const [productPriceSuggested, setProductPriceSuggested] = useState();

  const { products, productsLoading } = useProduct();
  const productSelect = toProductSelect(products);

  const handleProductChange = (product) => {
    setProductID(product._id);
    setProductName(product.name);
    setProductPriceRetail(product.price.retail);
    setProductPriceWholesale(product.price.wholesale);
    setProductPriceSuggested(product.price.suggested);
  };

  useEffect(() => {
    if (products && products.length > 0) {
      const initialProduct = products[0];
      handleProductChange(initialProduct);
    }
  }, [products]);

  useEffect(() => {
    if (productID) {
      const selectedProduct = products.find(
        (product) => product._id === productID
      );
      handleProductChange(selectedProduct);
    }
  }, [productID]);

  const handleFormSubmit = async () => {
    setLoadingData(true);
    setFormError(false);
    const request = {
      id: productID,
      name: productName,
      price: {
        retail: productPriceRetail,
        wholesale: productPriceWholesale,
        suggested: productPriceSuggested,
      },
    };
    const response = await apiRequest.post("/product/edit", request);
    setLoadingData(false);
    if (response.status_code === 200) {
      window.location.href = "/product/view";
    } else {
      setFormError(response.error);
    }
  };
  return (
    <Section>
      <SectionHead>
        <SectionHeading>Uredi Proizvode</SectionHeading>
      </SectionHead>
      <SectionContent>
        {productsLoading ? (
          <LoadingData />
        ) : (
          <Form>
            {products && products.length > 0 ? (
              <>
                <FormSelect
                  placeholder={"Proizvod"}
                  options={productSelect}
                  onChange={(e) => {
                    setProductID(e.target.value);
                  }}
                />
                <FormInputHolder>
                  <FormInput
                    icon={"boxes-stacked"}
                    placeholder={"Naziv"}
                    value={productName}
                    onChange={(e) => {
                      setProductName(e.target.value);
                    }}
                  />
                  <FormInput
                    icon={"euro-sign"}
                    placeholder={"Maloprodajna Cena (RSD)"}
                    value={productPriceRetail}
                    onChange={(e) => {
                      setProductPriceRetail(e.target.value);
                    }}
                  />
                  <FormInput
                    icon={"euro-sign"}
                    placeholder={"Veleprodajna Cena (RSD)"}
                    value={productPriceWholesale}
                    onChange={(e) => {
                      setProductPriceWholesale(e.target.value);
                    }}
                  />
                  <FormInput
                    icon={"euro-sign"}
                    placeholder={"Predložena Cena (RSD)"}
                    value={productPriceSuggested}
                    onChange={(e) => {
                      setProductPriceSuggested(e.target.value);
                    }}
                  />
                </FormInputHolder>
                {formError && <FormError>{formError}</FormError>}
                <FormSubmit
                  onClick={() => handleFormSubmit()}
                  isLoading={formLoading}
                >
                  Uredi
                </FormSubmit>
              </>
            ) : (
              <>
                <FormError>Nema Proizvoda za Uređivanje</FormError>
                <FormLink to="/product/add">Dodaj Proizvod</FormLink>
              </>
            )}
          </Form>
        )}
      </SectionContent>
    </Section>
  );
};

export default ProductEdit;
