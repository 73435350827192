import React, { useEffect, useState } from "react";
import Section from "../../components/section/Section";
import SectionHead from "../../components/section/SectionHead";
import SectionContent from "../../components/section/SectionContent";
import Form from "../../components/form/Form";
import FormInput from "../../components/form/FormInput";
import apiRequest from "../../api/apiRequest";
import FormError from "../../components/form/FormError";
import SectionHeading from "../../components/typography/SectionHeading";
import toDateFormated from "../../functions/toDateFormated";
import useCookie from "../../hooks/useCookie";
import LoadingData from "../../components/data/LoadingData";
import toDateReadable from "../../functions/toDateReadable";
import FormNotice from "../../components/form/FormNotice";
import FormLink from "../../components/form/FormLink";

const BusinessView = () => {
  const [formLoading, setLoadingData] = useState(true);
  const [formError, setFormError] = useState();

  const [businessIncomeTotal, setBusinessIncomeTotal] = useState(0);
  const [businessIncomeLocal, setBusinessIncomeLocal] = useState(0);
  const [businessIncomeWholesale, setBusinessIncomeWholesale] = useState(0);
  const [businessIncomeOther, setBusinessIncomeOther] = useState(0);

  const [businessExpenseTotal, setBusinessExpenseTotal] = useState(0);
  const [businessExpenseRegular, setBusinessExpenseRegular] = useState(0);
  const [businessExpenseUnregular, setBusinessExpenseUnregular] = useState(0);
  const [businessExpenseLocal, setBusinessExpenseLocal] = useState(0);

  const [businessDateStartCookie, setBusinessDateStartCookie] = useCookie(
    "business-view-date-start"
  );
  const [businessDateEndCookie, setBusinessDateEndCookie] = useCookie(
    "business-view-date-end"
  );

  const [businessDateStart, setBusinessDateStart] = useState(
    businessDateStartCookie ? businessDateStartCookie : toDateFormated()
  );
  const [businessDateEnd, setBusinessDateEnd] = useState(
    businessDateEndCookie
      ? businessDateEndCookie
      : toDateFormated(
          new Date(new Date().getTime() + 16 * 24 * 60 * 60 * 1000)
        )
  );
  const [businessData, setBusinessData] = useState();

  const handleBusinessDateStartChange = (date) => {
    const isValidDate = new Date(date);
    if (!isNaN(isValidDate)) {
      setBusinessDateStart(date);
      setBusinessDateStartCookie(date);
    }
  };

  const handleBusinessDateEndChange = (date) => {
    const isValidDate = new Date(date);
    if (!isNaN(isValidDate)) {
      setBusinessDateEnd(date);
      setBusinessDateEndCookie(date);
    }
  };

  useEffect(() => {
    const getBusiness = async () => {
      setLoadingData(true);
      setFormError(false);
      const response = await apiRequest.get(
        `/business/get?date_start=${businessDateStart}&date_end=${businessDateEnd}`
      );
      setLoadingData(false);
      if (response.status_code === 200) {
        const result = response.result;
        setBusinessData(result);
      } else {
        setFormError(response.error);
      }
    };
    getBusiness();
  }, [businessDateStart, businessDateEnd]);

  const [totalAmounts, setTotalAmounts] = useState([]);

  useEffect(() => {
    let businessIncomeTotal = 0;
    let businessIncomeLocal = 0;
    let businessIncomeWholesale = 0;
    let businessIncomeOther = 0;
    let businessExpenseTotal = 0;
    let businessExpenseRegular = 0;
    let businessExpenseUnregular = 0;
    let businessExpenseOther = 0;
    if (totalAmounts && totalAmounts.length > 0) {
      totalAmounts.map((totalAmount) => {
        const businessIncome = totalAmount.income;
        const businessExpense = totalAmount.expense;

        businessIncomeTotal += businessIncome.total;
        businessIncomeLocal += businessIncome.local;
        businessIncomeWholesale += businessIncome.wholesale;
        businessIncomeOther += businessIncome.other;

        businessExpenseTotal += businessExpense.total;
        businessExpenseRegular += businessExpense.regular;
        businessExpenseUnregular += businessExpense.unregular;
        businessExpenseOther += businessExpense.other;
      });
      setBusinessIncomeTotal(businessIncomeTotal);
      setBusinessIncomeLocal(businessIncomeLocal);
      setBusinessIncomeWholesale(businessIncomeWholesale);
      setBusinessIncomeOther(businessIncomeOther);
      setBusinessExpenseTotal(businessExpenseTotal);
      setBusinessExpenseRegular(businessExpenseRegular);
      setBusinessExpenseUnregular(businessExpenseUnregular);
      setBusinessExpenseLocal(businessExpenseLocal);
    }
  }, [totalAmounts]);

  useEffect(() => {
    if (businessData) {
      const businesssGraph = [];
      businessData.forEach((business) => {
        const category = business.category;
        const type = business.type;
        const amount = parseFloat(business.amount);
        const date = toDateReadable(new Date(business.date));
        const existingIndex = businesssGraph.findIndex(
          (entry) => entry.date === date
        );
        if (existingIndex !== -1) {
          if (category === "income") {
            businesssGraph[existingIndex].income.total += amount;
            if (type === "local") {
              businesssGraph[existingIndex].income.local += amount;
            }
            if (type === "wholesale") {
              businesssGraph[existingIndex].income.wholesale += amount;
            }
            if (type === "other") {
              businesssGraph[existingIndex].income.other += amount;
            }
          } else if (category === "expense") {
            businesssGraph[existingIndex].expense.total += amount;
            if (type === "regular") {
              businesssGraph[existingIndex].expense.regular += amount;
            }
            if (type === "unregular") {
              businesssGraph[existingIndex].expense.unregular += amount;
            }
            if (type === "local") {
              businesssGraph[existingIndex].expense.local += amount;
            }
          }
        } else {
          if (category === "income") {
            businesssGraph.push({
              date,
              income: {
                total: amount,
                local: type === "local" ? amount : 0,
                wholesale: type === "wholesale" ? amount : 0,
                other: type === "other" ? amount : 0,
              },
              expense: {
                total: 0,
                regular: 0,
                unregular: 0,
                local: 0,
              },
            });
          } else if (category === "expense") {
            businesssGraph.push({
              date,
              income: { total: 0, local: 0, wholesale: 0, other: 0 },
              expense: {
                total: amount,
                regular: type === "regular" ? amount : 0,
                unregular: type === "unregular" ? amount : 0,
                local: type === "local" ? amount : 0,
              },
            });
          }
        }
      });

      setTotalAmounts(businesssGraph);
    }
  }, [businessData]);
  return (
    <Section>
      <SectionHead>
        <SectionHeading>Poslovanje - Pregledaj</SectionHeading>
      </SectionHead>
      <SectionContent>
        <Form>
          <FormInput
            type="date"
            icon={"calendar-day"}
            value={businessDateStart}
            placeholder={"Početni Datum"}
            onChange={(e) => {
              handleBusinessDateStartChange(e.target.value);
            }}
          />
          <FormInput
            type="date"
            icon={"calendar-day"}
            value={businessDateEnd}
            placeholder={"Završni Datum"}
            onChange={(e) => {
              handleBusinessDateEndChange(e.target.value);
            }}
          />
          {formError && <FormError>{formError}</FormError>}
          {formLoading ? (
            <LoadingData />
          ) : totalAmounts && totalAmounts.length > 0 ? (
            <>
              <div className="overflow-x-auto flex px-4 py-6 my-6 w-full">
                <ul className="inline-flex gap-4 mx-auto">
                  {totalAmounts.map((business, key) => {
                    const businessIncome = business.income;
                    const businessIncomeTotal = businessIncome.total;
                    const businessExpense = business.expense;
                    const businessExpenseTotal = businessExpense.total;
                    const businessTotal =
                      businessIncomeTotal - businessExpenseTotal;
                    const businessDate = business.date;
                    return (
                      <li key={businessDate} className="flex flex-col">
                        <div>
                          <div className="bg-red-700 font-bold flex h-10 items-center justify-center rounded-t-lg text-theme-color--primaryContrast sm:text-lg xl:h-16">
                            {businessExpenseTotal} RSD
                          </div>
                          <div className="bg-green-700 font-bold flex h-10 items-center justify-center text-theme-color--primaryContrast sm:text-lg xl:h-16">
                            {businessIncomeTotal} RSD
                          </div>
                        </div>
                        <div className="bg-theme-color--primary font-bold flex h-20 items-center justify-center rounded-b-lg text-theme-color--primaryContrast sm:text-lg xl:h-32">
                          {businessTotal} RSD
                        </div>
                        <div className="font-medium mt-2 px-4 sm:text-lg">
                          {businessDate}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="flex flex-wrap items-stretch gap-2 mt-2">
                <div className="bg-theme-color--primary flex-1 flex flex-col gap-2 items-center justify-center p-4 rounded text-theme-color--primaryContrast text-center">
                  <span className="font-bold">Dobitak</span>
                  <div className="flex flex-col items-center justify-center">
                    <span>
                      {businessIncomeTotal - businessExpenseTotal} RSD
                    </span>
                  </div>
                </div>

                <div className="bg-green-700 flex-1 flex flex-col gap-2 items-center justify-center p-4 rounded text-theme-color--primaryContrast text-center">
                  <span className="font-bold">Prihodi</span>
                  <div className="flex flex-col items-center justify-center">
                    <span>{businessIncomeTotal} RSD</span>
                    <br />
                    <span>Lokal: {businessIncomeLocal} RSD</span>
                    <span>Veleprodaja: {businessIncomeWholesale} RSD</span>
                    <span>Ostalo: {businessIncomeOther} RSD</span>
                  </div>
                </div>

                <div className="bg-red-700 flex-1 flex flex-col gap-2 items-center justify-center p-4 rounded text-theme-color--primaryContrast text-center">
                  <span className="font-bold">Rashodi</span>
                  <div className="flex flex-col items-center justify-center">
                    <span>{businessExpenseTotal} RSD</span>
                    <br />
                    <span>Vanredni: {businessExpenseUnregular} RSD</span>
                    <span>Redovni: {businessExpenseRegular} RSD</span>
                    <span>Lokal: {businessExpenseLocal} RSD</span>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <FormNotice>Nema Poslovanja za Pregledavanje</FormNotice>
              <FormLink to={"/business/add"}>Dodaj Poslovanje</FormLink>
            </>
          )}
        </Form>
      </SectionContent>
    </Section>
  );
};

export default BusinessView;
