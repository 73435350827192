import React, { useEffect, useState } from "react";
import FormInputHolder from "../../../components/form/FormInputHolder";
import FormSelect from "../../../components/form/FormSelect";
import FormInput from "../../../components/form/FormInput";
import FormSubmit from "../../../components/form/FormSubmit";
import Section from "../../../components/section/Section";
import Form from "../../../components/form/Form";
import useProductSelect from "../../../hooks/useProductSelect";
import FormNotice from "../../../components/form/FormNotice";
import SectionHeading from "../../../components/typography/SectionHeading";
import toFixedNumber from "../../../functions/toFixedNumber";
import SectionHead from "../../../components/section/SectionHead";
import SectionContent from "../../../components/section/SectionContent";
import FormError from "../../../components/form/FormError";
import FormLink from "../../../components/form/FormLink";
import useCookie from "../../../hooks/useCookie";
import useWarehouseProduct from "../../../hooks/useWarehouseProduct";
import apiRequest from "../../../api/apiRequest";
import toDateFormated from "../../../functions/toDateFormated";

const ProduceCreamSpread = () => {
  const [formLoading, setFormLoading] = useState(false);
  const [formError, setFormError] = useState();
  const [isProducable, setIsProducable] = useState(false);
  const [productionDate, setProductionDate] = useCookie("production-date");

  const {
    warehouseProducts: warehouseCream40,
    warehouseRefetch: warehouseCream40Refetch,
  } = useWarehouseProduct(
    `product=6608b3b9a98f69aa0dab1431&amount=unused&date_produced=${productionDate}`
  );
  const {
    warehouseProducts: warehouseFreshCheese,
    warehouseRefetch: warehouseFreshCheeseRefetch,
  } = useWarehouseProduct(
    `product=66097840ec44f5842926972b&amount=unused&date_produced=${productionDate}`
  );
  const {
    warehouseProducts: warehouseSalt,
    warehouseRefetch: warehouseSaltRefetch,
  } = useWarehouseProduct(
    `product=660478b366de10b314ad7f17&amount=unused&date_produced=${productionDate}`
  );
  const warehouseCream40Select = useProductSelect(warehouseCream40, "kg");
  const warehouseFreshCheeseSelect = useProductSelect(
    warehouseFreshCheese,
    "kg"
  );
  const warehouseSaltSelect = useProductSelect(warehouseSalt, "kg");

  const [cream40ID, setCream40ID] = useState();
  const [cream40Amount, setCream40Amount] = useState(0);

  const [freshCheeseID, setFreshCheeseID] = useState();
  const [freshCheeseAmount, setFreshCheeseAmount] = useState(0);

  const [saltID, setSaltID] = useState();
  const [saltAmount, setSaltAmount] = useState(0);

  const [producesCream, setProducesCream] = useState();

  useEffect(() => {
    if (warehouseCream40 && warehouseCream40.length > 0) {
      const initialWarehouseCream40 = warehouseCream40[0];
      const initialWarehouseCream40ID = initialWarehouseCream40._id;
      setCream40ID(initialWarehouseCream40ID);
    }
  }, [warehouseCream40]);

  useEffect(() => {
    if (warehouseFreshCheese && warehouseFreshCheese.length > 0) {
      const initialWarehouseFreshCheese = warehouseFreshCheese[0];
      const initialWarehouseFreshCheeseID = initialWarehouseFreshCheese._id;
      setFreshCheeseID(initialWarehouseFreshCheeseID);
    }
  }, [warehouseFreshCheese]);

  useEffect(() => {
    if (warehouseSalt && warehouseSalt.length > 0) {
      const initialWarehouseSalt = warehouseSalt[0];
      const initialWarehouseSaltID = initialWarehouseSalt._id;
      setSaltID(initialWarehouseSaltID);
    }
  }, [warehouseSalt]);

  useEffect(() => {
    handleReset();
  }, [cream40ID, freshCheeseID, saltID]);

  const handleReset = () => {
    setCream40Amount("");
    setFreshCheeseAmount("");
    setSaltAmount("");
  };

  const handleCream40Change = (input) => {
    if (input) {
      const selectedCream40 = warehouseCream40.find(
        (product) => product._id === cream40ID
      );
      const maxCream40Amount = toFixedNumber(
        selectedCream40.amount.produced - selectedCream40.amount.used
      );
      const cream40Amount = input > maxCream40Amount ? maxCream40Amount : input;

      const freshCheeseAmount = toFixedNumber(cream40Amount * 0.1);
      const saltAmount = toFixedNumber(
        (cream40Amount + freshCheeseAmount) * 0.01
      );

      setCream40Amount(cream40Amount);
      setFreshCheeseAmount(freshCheeseAmount);
      setSaltAmount(saltAmount);
    } else {
      handleReset();
    }
  };

  const handleFreshCheeseChange = (input) => {
    if (input) {
      const selectedFreshCheese = warehouseFreshCheese.find(
        (product) => product._id === freshCheeseID
      );
      const maxFreshCheeseAmount = toFixedNumber(
        selectedFreshCheese.amount.produced - selectedFreshCheese.amount.used
      );
      const freshCheeseAmount =
        input > maxFreshCheeseAmount ? maxFreshCheeseAmount : input;
      setFreshCheeseAmount(freshCheeseAmount);
      setCream40Amount(freshCheeseAmount * 10);
      setSaltAmount(
        toFixedNumber((freshCheeseAmount + freshCheeseAmount * 10) * 0.01)
      );
    } else {
      handleReset();
    }
  };

  const handleSaltChange = (input) => {
    if (input) {
      const selectedSalt = warehouseSalt.find(
        (product) => product._id === saltID
      );
      const maxSaltAmount = toFixedNumber(
        selectedSalt.amount.produced - selectedSalt.amount.used
      );
      const saltAmount = input > maxSaltAmount ? maxSaltAmount : input;
      setSaltAmount(saltAmount);
      setCream40Amount(toFixedNumber((saltAmount * 100) / 2 / 1.1));
      setFreshCheeseAmount(toFixedNumber(((saltAmount * 100) / 2 / 1.1) * 0.1));
    } else {
      handleReset();
    }
  };

  useEffect(() => {
    if (
      warehouseCream40 &&
      cream40ID &&
      cream40Amount > 0 &&
      warehouseFreshCheese &&
      freshCheeseID &&
      freshCheeseAmount > 0 &&
      warehouseSalt &&
      saltID &&
      saltAmount > 0
    ) {
      setIsProducable(true);
      const selectedCream40 = warehouseCream40.find(
        (product) => product._id === cream40ID
      );
      const maxCream40Amount = toFixedNumber(
        selectedCream40.amount.produced - selectedCream40.amount.used
      );
      const selectedFreshCheese = warehouseFreshCheese.find(
        (product) => product._id === freshCheeseID
      );
      const maxFreshCheeseAmount = toFixedNumber(
        selectedFreshCheese.amount.produced - selectedFreshCheese.amount.used
      );
      const selectedSalt = warehouseSalt.find(
        (product) => product._id === saltID
      );
      const maxSaltAmount = toFixedNumber(
        selectedSalt.amount.produced - selectedSalt.amount.used
      );
      if (cream40Amount > maxCream40Amount) {
        handleCream40Change(cream40Amount);
      } else if (freshCheeseAmount > maxFreshCheeseAmount) {
        handleFreshCheeseChange(freshCheeseAmount);
      } else if (saltAmount > maxSaltAmount) {
        handleSaltChange(saltAmount);
      }
    } else {
      setIsProducable(false);
    }
  }, [
    warehouseCream40,
    cream40ID,
    cream40Amount,

    warehouseFreshCheese,
    freshCheeseID,
    freshCheeseAmount,

    warehouseSalt,
    saltID,
    saltAmount,
  ]);

  useEffect(() => {
    if (
      warehouseCream40 &&
      warehouseCream40.length > 0 &&
      cream40Amount &&
      warehouseFreshCheese &&
      warehouseFreshCheese.length > 0 &&
      freshCheeseAmount
    ) {
      const producesCream = cream40Amount + freshCheeseAmount;
      setProducesCream(toFixedNumber(producesCream));
    }
  }, [
    warehouseCream40,
    cream40Amount,
    warehouseFreshCheese,
    freshCheeseAmount,
  ]);

  useEffect(() => {
    warehouseCream40Refetch();
    warehouseFreshCheeseRefetch();
    warehouseSaltRefetch();
  }, [productionDate]);

  const handleProductionDateChange = (date) => {
    setProductionDate(date, 1);
  };

  const handleProductionSubmit = async () => {
    setFormError("");
    setFormLoading(true);
    const request = {
      produced: [
        {
          product: "660978a0ec44f5842926972e",
          product_type: "ProductRaw",
          amount: producesCream,
        },
      ],
      used: [
        { product: cream40ID, amount: cream40Amount },
        { product: freshCheeseID, amount: freshCheeseAmount },
        { product: saltID, amount: saltAmount },
      ],
      date: { produced: new Date(productionDate) },
    };

    const response = await apiRequest.post("/production/add", request);

    if (response.status_code === 200) {
      window.location.reload();
    } else {
      setFormError(response.error);
    }
  };

  return (
    <Section>
      <SectionHead>
        <SectionHeading>Proizvedi - Pavlaka Mlečni Namaz</SectionHeading>
      </SectionHead>
      <SectionContent>
        <Form>
          <FormInput
            type="date"
            icon={"calendar-day"}
            value={productionDate ? productionDate : toDateFormated()}
            placeholder={"Datum Proizvodnje"}
            onChange={(e) => {
              handleProductionDateChange(e.target.value);
            }}
          />
          <FormInputHolder>
            {warehouseCream40 && warehouseCream40.length > 0 ? (
              <>
                <FormSelect
                  placeholder={"Pavlaka Poluproizvod 40% m.m"}
                  options={warehouseCream40Select}
                  value={cream40ID}
                  onChange={(e) => {
                    setCream40ID(e.target.value);
                  }}
                />
                <FormInput
                  icon={"droplet"}
                  placeholder={"Količina (KG)"}
                  type="number"
                  step="0.5"
                  min={1}
                  value={cream40Amount}
                  onChange={(e) => {
                    const input = e.target.value;
                    if (/^\d*\.?\d{0,2}$/.test(input)) {
                      handleCream40Change(parseFloat(input));
                    }
                  }}
                />
              </>
            ) : (
              <>
                <FormError>
                  Nedostaje Pavlaka Poluproizvod 40% m.m za proizvodnju
                </FormError>
                <FormLink to={"/production/produce/cream-40"}>
                  Proizvedi Pavlaku Poluproizvod 40% m.m
                </FormLink>
              </>
            )}
          </FormInputHolder>
          <FormInputHolder>
            {warehouseFreshCheese && warehouseFreshCheese.length > 0 ? (
              <>
                <FormSelect
                  placeholder={"Sveži sir"}
                  options={warehouseFreshCheeseSelect}
                  value={freshCheeseID}
                  onChange={(e) => {
                    setFreshCheeseID(e.target.value);
                  }}
                />
                <FormInput
                  icon={"droplet"}
                  placeholder={"Količina (KG)"}
                  type="number"
                  step="0.5"
                  min={1}
                  value={freshCheeseAmount}
                  onChange={(e) => {
                    const input = e.target.value;
                    if (/^\d*\.?\d{0,2}$/.test(input)) {
                      handleFreshCheeseChange(parseFloat(input));
                    }
                  }}
                />
              </>
            ) : (
              <>
                <FormError>Nedostaje Sveži sir za proizvodnju</FormError>
                <FormLink to={"/production/produce/cheese-fresh"}>
                  Proizvedi Sveži sir
                </FormLink>
              </>
            )}
          </FormInputHolder>
          <FormInputHolder>
            {warehouseSalt && warehouseSalt.length > 0 ? (
              <>
                <FormSelect
                  placeholder={"So"}
                  options={warehouseSaltSelect}
                  value={saltID}
                  onChange={(e) => {
                    setSaltID(e.target.value);
                  }}
                />
                <FormInput
                  icon={"droplet"}
                  placeholder={"Količina (KG)"}
                  type="number"
                  step="0.01"
                  min={1}
                  value={saltAmount}
                  onChange={(e) => {
                    const input = e.target.value;
                    if (/^\d*\.?\d{0,2}$/.test(input)) {
                      handleSaltChange(parseFloat(input));
                    }
                  }}
                />
              </>
            ) : (
              <>
                <FormError>Nedostaje So za proizvodnju</FormError>
                <FormLink to={"/warehouse/add/material"}>Dodaj So</FormLink>
              </>
            )}
          </FormInputHolder>
          {producesCream && (
            <FormInputHolder>
              <FormInput
                icon={"scale-balanced"}
                placeholder={"Proizvedi (KG)"}
                type="number"
                min={1}
                value={producesCream}
                onChange={(e) => {
                  const input = e.target.value;
                  if (/^\d*\.?\d{0,2}$/.test(input)) {
                    setProducesCream(input);
                  }
                }}
              />
              <FormNotice>
                Proizvedi {producesCream}kg Pavlaka Mlečni Namaz
              </FormNotice>
            </FormInputHolder>
          )}
          {isProducable && (
            <FormSubmit onClick={handleProductionSubmit}>Proizvedi</FormSubmit>
          )}
        </Form>
      </SectionContent>
    </Section>
  );
};

export default ProduceCreamSpread;
