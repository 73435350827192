import React from "react";
import NavMenuToggler from "./NavMenuToggler";
import NavMenu from "./NavMenu";

const Nav = () => {
  return (
    <li className="overflow-hidden xl:h-full xl:w-full">
      <NavMenuToggler />
      <NavMenu />
    </li>
  );
};

export default Nav;
