import React from "react";
import Icon from "../icon/Icon";

const ViewItem = ({ className, children }) => {
  return (
    <li
      className={`bg-white border border-theme-color--listItemBorder rounded-lg shadow-sm ${
        className ? className : ""
      }`}
    >
      <div className="flex flex-col items-center justify-center gap-4 h-full p-4 text-center w-full sm:p-10 lg:gap-6 xl:p-8">
        {children}
      </div>
    </li>
  );
};

{
  /* <li className="border p-4 rounded-lg sm:p-5">
  <div className="flex gap-4">
    <div className="text-xl">
      <Icon icon={icon} />
    </div>
    <h2 className="font-medium">{heading}</h2>
  </div>
</li>; */
}

export default ViewItem;
