import React from "react";
import Section from "../../components/section/Section";
import SectionHeading from "../../components/typography/SectionHeading";
import SectionHead from "../../components/section/SectionHead";
import SectionContent from "../../components/section/SectionContent";

const DriverEdit = () => {
  return (
    <Section>
      <SectionHead>
        <SectionHeading>Vozači - Uredi</SectionHeading>
      </SectionHead>
      <SectionContent>
        <span>U Izradi</span>
      </SectionContent>
    </Section>
  );
};

export default DriverEdit;
