import { useEffect, useState } from "react";
import apiRequest from "../../api/apiRequest";

const useFarmer = (query) => {
  const [farmers, setFarmers] = useState();
  const [farmersLoading, setFarmersLoading] = useState(true);
  const [farmersError, setFarmersError] = useState(null);

  const getFarmers = async () => {
    try {
      const response = await apiRequest.get(`/farmer/get?${query}`);
      if (response.status_code === 200 && response.result) {
        const farmers = response.result;
        setFarmers(farmers);
      } else {
        setFarmersError("Failed to fetch data");
      }
    } catch (error) {
      setFarmersError("Failed to fetch data");
    } finally {
      setFarmersLoading(false);
    }
  };

  useEffect(() => {
    getFarmers();
  }, []);

  return {
    farmers,
    farmersLoading,
    farmersError,
    farmersRefetch: getFarmers,
  }; // Return a promise if needed
};

export default useFarmer;
