import React, { useEffect, useState } from "react";
import FormInputHolder from "../../../components/form/FormInputHolder";
import FormSelect from "../../../components/form/FormSelect";
import FormInput from "../../../components/form/FormInput";
import FormSubmit from "../../../components/form/FormSubmit";
import Section from "../../../components/section/Section";
import Form from "../../../components/form/Form";
import useProductSelect from "../../../hooks/useProductSelect";
import FormNotice from "../../../components/form/FormNotice";
import SectionHeading from "../../../components/typography/SectionHeading";
import toFixedNumber from "../../../functions/toFixedNumber";
import SectionHead from "../../../components/section/SectionHead";
import SectionContent from "../../../components/section/SectionContent";
import FormError from "../../../components/form/FormError";
import FormLink from "../../../components/form/FormLink";
import useCookie from "../../../hooks/useCookie";
import useWarehouseProduct from "../../../hooks/useWarehouseProduct";
import apiRequest from "../../../api/apiRequest";
import toDateFormated from "../../../functions/toDateFormated";

const ProduceCreamPepper = () => {
  const [formLoading, setFormLoading] = useState(false);
  const [formError, setFormError] = useState();
  const [isProducable, setIsProducable] = useState(false);
  const [productionDate, setProductionDate] = useCookie("production-date");

  const {
    warehouseProducts: warehouseCream,
    warehouseRefetch: warehouseCreamRefetch,
  } = useWarehouseProduct(
    `product=660978a0ec44f5842926972e&amount=unused&date_produced=${productionDate}`
  );
  const {
    warehouseProducts: warehousePepper,
    warehouseRefetch: warehousePepperRefetch,
  } = useWarehouseProduct(
    `product=660e0877b81436333911bd26&amount=unused&date_produced=${productionDate}`
  );

  const warehouseCreamSelect = useProductSelect(warehouseCream, "kg");
  const warehousePepperSelect = useProductSelect(warehousePepper, "kg");

  const [creamID, setCreamID] = useState();
  const [creamAmount, setCreamAmount] = useState(0);

  const [freshCheeseID, setPepperID] = useState();
  const [pepperAmount, setPepperAmount] = useState(0);

  const [producesCreamPepper, setProducesCreamPepper] = useState();

  useEffect(() => {
    if (warehouseCream && warehouseCream.length > 0) {
      const initialWarehouseCream = warehouseCream[0];
      const initialWarehouseCreamID = initialWarehouseCream._id;
      setCreamID(initialWarehouseCreamID);
    }
  }, [warehouseCream]);

  useEffect(() => {
    if (warehousePepper && warehousePepper.length > 0) {
      const initialWarehousePepper = warehousePepper[0];
      const initialWarehousePepperID = initialWarehousePepper._id;
      setPepperID(initialWarehousePepperID);
    }
  }, [warehousePepper]);

  useEffect(() => {
    handleReset();
  }, [creamID, freshCheeseID]);

  const handleReset = () => {
    setCreamAmount("");
    setPepperAmount("");
  };

  const handleCreamChange = (input) => {
    if (input) {
      const selectedCream = warehouseCream.find(
        (product) => product._id === creamID
      );
      const maxCreamAmount = toFixedNumber(
        selectedCream.amount.produced - selectedCream.amount.used
      );

      const creamAmount = input > maxCreamAmount ? maxCreamAmount : input;
      const pepperAmount = toFixedNumber(0.4 * (creamAmount / 0.6));

      setCreamAmount(creamAmount);
      setPepperAmount(pepperAmount);
    } else {
      handleReset();
    }
  };

  const handlePepperChange = (input) => {
    if (input) {
      const selectedPepper = warehousePepper.find(
        (product) => product._id === freshCheeseID
      );
      const maxPepperAmount = toFixedNumber(
        selectedPepper.amount.produced - selectedPepper.amount.used
      );
      const pepperAmount = input > maxPepperAmount ? maxPepperAmount : input;
      setPepperAmount(pepperAmount);
      setCreamAmount(0.6 * (pepperAmount / 0.4));
    } else {
      handleReset();
    }
  };

  useEffect(() => {
    if (
      warehouseCream &&
      creamID &&
      creamAmount > 0 &&
      warehousePepper &&
      freshCheeseID &&
      pepperAmount > 0
    ) {
      setIsProducable(true);
      const selectedCream = warehouseCream.find(
        (product) => product._id === creamID
      );
      const maxCreamAmount = toFixedNumber(
        selectedCream.amount.produced - selectedCream.amount.used
      );
      const selectedPepper = warehousePepper.find(
        (product) => product._id === freshCheeseID
      );
      const maxPepperAmount = toFixedNumber(
        selectedPepper.amount.produced - selectedPepper.amount.used
      );
      if (creamAmount > maxCreamAmount) {
        handleCreamChange(creamAmount);
      } else if (pepperAmount > maxPepperAmount) {
        handlePepperChange(pepperAmount);
      }
    } else {
      setIsProducable(false);
    }
  }, [
    warehouseCream,
    creamID,
    creamAmount,

    warehousePepper,
    freshCheeseID,
    pepperAmount,
  ]);

  useEffect(() => {
    if (
      warehouseCream &&
      warehouseCream.length > 0 &&
      creamAmount &&
      warehousePepper &&
      warehousePepper.length > 0 &&
      pepperAmount
    ) {
      const producesCreamPepper = creamAmount + pepperAmount;
      setProducesCreamPepper(producesCreamPepper);
    }
  }, [warehouseCream, creamAmount, warehousePepper, pepperAmount]);

  useEffect(() => {
    warehouseCreamRefetch();
    warehousePepperRefetch();
  }, [productionDate]);

  const handleProductionDateChange = (date) => {
    setProductionDate(date, 1);
  };

  const handleProductionSubmit = async () => {
    setFormError("");
    setFormLoading(true);
    const request = {
      produced: [
        {
          product: "660978acec44f5842926972f",
          product_type: "ProductRaw",
          amount: producesCreamPepper,
        },
      ],
      used: [
        { product: creamID, amount: creamAmount },
        { product: freshCheeseID, amount: pepperAmount },
      ],
      date: { produced: new Date(productionDate) },
    };

    const response = await apiRequest.post("/production/add", request);

    if (response.status_code === 200) {
      window.location.reload();
    } else {
      setFormError(response.error);
    }
  };

  return (
    <Section>
      <SectionHead>
        <SectionHeading>Proizvedi - Paprika u Pavlaci</SectionHeading>
      </SectionHead>
      <SectionContent>
        <Form>
          <FormInput
            type="date"
            icon={"calendar-day"}
            value={productionDate ? productionDate : toDateFormated()}
            placeholder={"Datum Proizvodnje"}
            onChange={(e) => {
              handleProductionDateChange(e.target.value);
            }}
          />
          <FormInputHolder>
            {warehouseCream && warehouseCream.length > 0 ? (
              <>
                <FormSelect
                  placeholder={"Pavlaka Mlečni Namaz"}
                  options={warehouseCreamSelect}
                  value={creamID}
                  onChange={(e) => {
                    setCreamID(e.target.value);
                  }}
                />
                <FormInput
                  icon={"scale-balanced"}
                  placeholder={"Količina (KG)"}
                  type="number"
                  step="0.5"
                  min={1}
                  value={creamAmount}
                  onChange={(e) => {
                    const input = e.target.value;
                    if (/^\d*\.?\d{0,2}$/.test(input)) {
                      handleCreamChange(parseFloat(input));
                    }
                  }}
                />
              </>
            ) : (
              <>
                <FormError>
                  Nedostaje Pavlaka Mlečni Namaz za proizvodnju
                </FormError>
                <FormLink to={"/production/produce/cream"}>
                  Proizvedi Pavlaku Mlečni Namaz
                </FormLink>
              </>
            )}
          </FormInputHolder>
          <FormInputHolder>
            {warehousePepper && warehousePepper.length > 0 ? (
              <>
                <FormSelect
                  placeholder={"Paprika"}
                  options={warehousePepperSelect}
                  value={freshCheeseID}
                  onChange={(e) => {
                    setPepperID(e.target.value);
                  }}
                />
                <FormInput
                  icon={"droplet"}
                  placeholder={"Količina (KG)"}
                  type="number"
                  step="0.5"
                  min={1}
                  value={pepperAmount}
                  onChange={(e) => {
                    const input = e.target.value;
                    if (/^\d*\.?\d{0,2}$/.test(input)) {
                      handlePepperChange(parseFloat(input));
                    }
                  }}
                />
              </>
            ) : (
              <>
                <FormError>Nedostaje Paprika za proizvodnju</FormError>
                <FormLink to={"/warehouse/add/material"}>
                  Dodaj Paprika
                </FormLink>
              </>
            )}
          </FormInputHolder>
          {producesCreamPepper && (
            <FormInputHolder>
              <FormInput
                icon={"scale-balanced"}
                placeholder={"Proizvedi (KG)"}
                type="number"
                min={1}
                value={producesCreamPepper}
                onChange={(e) => {
                  const input = e.target.value;
                  if (/^\d*\.?\d{0,2}$/.test(input)) {
                    setProducesCreamPepper(input);
                  }
                }}
              />
              <FormNotice>
                Proizvedi {producesCreamPepper}kg Paprike u Pavlaci
              </FormNotice>
            </FormInputHolder>
          )}
          {isProducable && (
            <FormSubmit onClick={handleProductionSubmit}>Proizvedi</FormSubmit>
          )}
        </Form>
      </SectionContent>
    </Section>
  );
};

export default ProduceCreamPepper;
