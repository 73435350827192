import { useEffect, useState } from "react";
import apiRequest from "../../api/apiRequest";

const useMaterial = (query) => {
  const [productMaterials, setMaterials] = useState();
  const [productMaterialsLoading, setMaterialsLoading] = useState(true);
  const [productMaterialsError, setMaterialsError] = useState(null);

  const getMaterials = async () => {
    try {
      const response = await apiRequest.get(`/material/get?${query}`);
      if (response.status_code === 200 && response.result) {
        const productMaterials = response.result;
        setMaterials(productMaterials);
      } else {
        setMaterialsError("Failed to fetch data");
      }
    } catch (error) {
      setMaterialsError("Failed to fetch data");
    } finally {
      setMaterialsLoading(false);
    }
  };

  useEffect(() => {
    getMaterials();
  }, []);

  return {
    productMaterials,
    productMaterialsLoading,
    productMaterialsError,
    productMaterialsRefetch: getMaterials,
  }; // Return a promise if needed
};

export default useMaterial;
