import { useEffect, useState } from "react";
import apiRequest from "../../api/apiRequest";

const usePackage = (query) => {
  const [productPackages, setPackages] = useState();
  const [productPackagesLoading, setPackagesLoading] = useState(true);
  const [productPackagesError, setPackagesError] = useState(null);

  const getPackages = async () => {
    try {
      const response = await apiRequest.get(`/package/get?${query}`);
      if (response.status_code === 200 && response.result) {
        const productPackages = response.result;
        setPackages(productPackages);
      } else {
        setPackagesError("Failed to fetch data");
      }
    } catch (error) {
      setPackagesError("Failed to fetch data");
    } finally {
      setPackagesLoading(false);
    }
  };

  useEffect(() => {
    getPackages();
  }, []);

  return {
    productPackages,
    productPackagesLoading,
    productPackagesError,
    productPackagesRefetch: getPackages,
  }; // Return a promise if needed
};

export default usePackage;
