const toMaterialSelect = (productMaterials) => {
  const productMaterialSelect =
    productMaterials &&
    productMaterials.map((productMaterial) => {
      return {
        placeholder: productMaterial.name,
        value: productMaterial._id,
      };
    });
  return productMaterialSelect;
};

export default toMaterialSelect;
