import React from "react";
import Section from "../../../components/section/Section";
import SectionHeading from "../../../components/typography/SectionHeading";
import SectionHead from "../../../components/section/SectionHead";
import SectionContent from "../../../components/section/SectionContent";

const BusinessAddExpense = () => {
  return (
    <Section>
      <SectionHead>
        <SectionHeading>Poslovanje - Dodaj Rashod</SectionHeading>
      </SectionHead>
      <SectionContent>
        <span>U Izradi</span>
      </SectionContent>
    </Section>
  );
};

export default BusinessAddExpense;
