import { useState } from "react";

function useCookie(cookieName) {
  const getCookie = () => {
    const cookies = document.cookie.split("; ");
    const cookie = cookies.find((cookie) => cookie.startsWith(cookieName));
    if (cookie) {
      return cookie.split("=")[1];
    }
    return null;
  };

  const [cookieValue, setCookieValue] = useState(getCookie());

  const setCookie = (value, expiryDays = 7) => {
    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + expiryDays);
    const cookieString = `${cookieName}=${value}; expires=${expiryDate.toUTCString()}; path=/`;
    document.cookie = cookieString;
    setCookieValue(value);
  };

  const removeCookie = () => {
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    setCookieValue(null);
  };

  return [cookieValue, setCookie, removeCookie];
}

export default useCookie;
