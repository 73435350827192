import { useEffect, useState } from "react";
import apiRequest from "../../api/apiRequest";

const useMilk = (query) => {
  const [milks, setMilks] = useState([]);
  const [milksLoading, setMilksLoading] = useState(true);
  const [milksError, setMilksError] = useState(null);

  const getMilks = async () => {
    setMilksLoading(true);
    setMilksError(null);
    try {
      const response = await apiRequest.get(`/milk/get?${query}`);
      if (response.status_code === 200 && response.result) {
        setMilks(response.result);
      } else {
        setMilksError("Failed to fetch data");
      }
    } catch (error) {
      setMilksError(error.message || "Failed to fetch data");
    } finally {
      setMilksLoading(false);
    }
  };

  useEffect(() => {
    getMilks();
  }, [query]);

  return {
    milks,
    milksLoading,
    milksError,
    milksRefetch: getMilks,
  };
};

export default useMilk;
