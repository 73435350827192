import { Document, Font, Image, Page, Text, View } from "@react-pdf/renderer";
import React from "react";
import toSizePDF from "../../../functions/toSizePDF";

import logo from "../../../assets/media/logo.png";
import PoppinsRegular from "../../../assets/fonts/PoppinsRegular-400.ttf";
import PoppinsBold from "../../../assets/fonts/PoppinsBold-700.ttf";

Font.register({
  family: "Poppins",
  src: PoppinsRegular,
});

Font.register({
  family: "Poppins",
  src: PoppinsBold,
});

const WholesalePDF = () => {
  // const { products, productsLoading } = useProduct();
  let productNum = 0;

  const products = [
    {
      name: "Paprika u pavlaci",
      unit: "1kg",
      price: 350,
      suggested: 420,
      barcode: 8606109419013,
    },
    {
      name: "Paprika u pavlaci",
      unit: "2kg",
      price: 700,
      suggested: 840,
      barcode: 8606109419020,
    },
    {
      name: "Domaća pavlaka",
      unit: "1kg",
      price: 330,
      suggested: 400,
      barcode: 8606109419112,
    },
    {
      name: "Domaća pavlaka",
      unit: "2kg",
      price: 660,
      suggested: 790,
      barcode: 8606109419129,
    },
    {
      name: "Domaća pavlaka",
      unit: "5kg",
      price: 1600,
      suggested: 1900,
      barcode: 8606109419136,
    },
    {
      name: "Seckana paprika u pavlaci",
      unit: "250gr",
      price: 150,
      suggested: 180,
      barcode: 8606109419228,
    },
    {
      name: "Seckana paprika u pavlaci",
      unit: "400gr",
      price: 230,
      suggested: 280,
      barcode: 8606109419198,
    },
    {
      name: "Seckana paprika u pavlaci",
      unit: "1kg",
      price: 380,
      suggested: 450,
      barcode: 8606109419211,
    },
    {
      name: "Seckana paprika u pavlaci",
      unit: "2kg",
      price: 760,
      suggested: 900,
      barcode: 8606109419235,
    },
    {
      name: "Kajmak namaz",
      unit: "150gr",
      price: 130,
      suggested: 160,
      barcode: 8606109419051,
    },
    {
      name: "Kajmak namaz",
      unit: "250gr",
      price: 210,
      suggested: 250,
      barcode: 8606109419068,
    },
    {
      name: "Kajmak namaz",
      unit: "400gr",
      price: 300,
      suggested: 360,
      barcode: 8606109419075,
    },
    {
      name: "Kajmak namaz",
      unit: "500gr",
      price: 350,
      suggested: 420,
      barcode: 8606109419082,
    },
    {
      name: "Metanica",
      unit: "500gr",
      price: 600,
      suggested: 700,
      barcode: 8606109419327,
    },
    {
      name: "Surutka",
      unit: "1l",
      price: 45,
      suggested: 60,
      barcode: 8606109419174,
    },
    {
      name: "Surutka",
      unit: "2l",
      price: 85,
      suggested: 100,
      barcode: 8606109419181,
    },
    {
      name: "Sir kriška",
      unit: "1kg",
      price: 660,
      suggested: 790,
      barcode: 8606109419167,
    },
    {
      name: "Vakuumirani sir iz kace",
      unit: "1kg",
      price: 750,
      suggested: 900,
      barcode: 8606109419297,
    },
    {
      name: "Topljeno maslo",
      unit: "1kg",
      price: 1080,
      suggested: 1270,
      barcode: 8606109419334,
    },
  ];
  return (
    <Document title="Veleprodajni Cenovnik">
      <Page
        size="A4"
        style={{
          backgroundColor: "#fff",
          fontFamily: "Poppins",
        }}
      >
        <Image
          src={logo}
          style={{
            width: toSizePDF(60),
            marginTop: toSizePDF(5),
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
        <View
          style={{
            textAlign: "center",
          }}
        >
          <View
            style={{
              fontSize: 12,
              marginTop: toSizePDF(10),
            }}
          >
            <Text>Ivanča 54, 36308</Text>
            <Text>PIB: 113813367, MB: 67103343, Tr. 155-84305-71</Text>
            <Text>Tel: 063 1635 963, Tel: 069 680 670</Text>
          </View>
          <View
            style={{
              fontSize: 24,
              fontWeight: 900,
              marginTop: toSizePDF(5),
            }}
          >
            <Text>Veleprodajni Cenovnik</Text>
          </View>
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            fontSize: 12,
            fontWeight: 700,
            justifyContent: "center",
            marginTop: toSizePDF(17),
          }}
        >
          <View
            style={{
              height: toSizePDF(14),
              width: toSizePDF(15),
              border: "1px solid #000",
              backgroundColor: "#13007c",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Text>Redni broj</Text>
          </View>
          <View
            style={{
              height: toSizePDF(14),
              width: toSizePDF(65),
              border: "1px solid #000",
              backgroundColor: "#13007c",
              color: "#fff",
              display: "flex",
              alignItems: "start",
              justifyContent: "center",
              paddingLeft: "10px",
            }}
          >
            <Text>Naziv artikla</Text>
          </View>
          <View
            style={{
              height: toSizePDF(14),
              width: toSizePDF(25),
              border: "1px solid #000",
              backgroundColor: "#13007c",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Text>Jedinica mere</Text>
          </View>
          <View
            style={{
              height: toSizePDF(14),
              width: toSizePDF(30),
              border: "1px solid #000",
              backgroundColor: "#13007c",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text>Cena</Text>
          </View>
          <View
            style={{
              height: toSizePDF(14),
              width: toSizePDF(30),
              border: "1px solid #000",
              backgroundColor: "#13007c",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text>Predlog za vašu cenu</Text>
          </View>
          <View
            style={{
              height: toSizePDF(14),
              width: toSizePDF(35),
              border: "1px solid #000",
              backgroundColor: "#13007c",
              color: "#fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text>Bar-kod</Text>
          </View>
        </View>
        {products &&
          products.map((product) => {
            productNum += 1;
            const fill = productNum % 2 === 1;
            const productName = product.name;
            const productUnit = product.unit;
            const productPrice = product.price;
            const productSuggested = product.suggested;
            const productBarcode = product.barcode;
            return (
              <>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    fontSize: 12,
                    justifyContent: "center",
                  }}
                >
                  <View
                    style={{
                      height: toSizePDF(10),
                      width: toSizePDF(15),
                      border: "1px solid #000",
                      backgroundColor: fill ? "#e0ebff" : "#fff",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text>{productNum}</Text>
                  </View>
                  <View
                    style={{
                      height: toSizePDF(10),
                      width: toSizePDF(65),
                      border: "1px solid #000",
                      backgroundColor: fill ? "#e0ebff" : "#fff",
                      display: "flex",
                      alignItems: "start",
                      justifyContent: "center",
                      paddingLeft: "10px",
                    }}
                  >
                    <Text>{productName}</Text>
                  </View>
                  <View
                    style={{
                      height: toSizePDF(10),
                      width: toSizePDF(25),
                      border: "1px solid #000",
                      backgroundColor: fill ? "#e0ebff" : "#fff",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text>{productUnit}</Text>
                  </View>
                  <View
                    style={{
                      height: toSizePDF(10),
                      width: toSizePDF(30),
                      border: "1px solid #000",
                      backgroundColor: fill ? "#e0ebff" : "#fff",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text>{productPrice} RSD</Text>
                  </View>
                  <View
                    style={{
                      height: toSizePDF(10),
                      width: toSizePDF(30),
                      border: "1px solid #000",
                      backgroundColor: fill ? "#e0ebff" : "#fff",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text>{productSuggested} RSD</Text>
                  </View>
                  <View
                    style={{
                      height: toSizePDF(10),
                      width: toSizePDF(35),
                      border: "1px solid #000",
                      backgroundColor: fill ? "#e0ebff" : "#fff",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Text>{productBarcode}</Text>
                  </View>
                </View>
              </>
            );
          })}
      </Page>
    </Document>
  );
};

export default WholesalePDF;
