import React from "react";
import Icon from "../icon/Icon";
import Label from "./Label";

const FormInput = ({
  refKey,
  icon,
  placeholder,
  type = "text",
  step,
  value,
  onChange,
}) => {
  return (
    <div>
      <Label>{placeholder}</Label>
      <div className="bg-theme-color--input-background border border-theme-color--inputBorder flex flex-row h-12 items-center overflow-hidden rounded-lg shadow xl:h-14">
        <div className="aspect-square border-r border-theme-color--inputBorder bg-theme-color--inputIcon flex h-full items-center justify-center xl:text-lg">
          <Icon icon={icon} />
        </div>
        <input
          ref={refKey}
          className="bg-theme-color--input-background flex-1 h-full outline-none px-4 text-base xl:text-lg"
          placeholder={placeholder}
          type={type}
          step={step}
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default FormInput;
