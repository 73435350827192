import React, { useEffect, useState } from "react";
import Section from "../../components/section/Section";
import SectionHeading from "../../components/typography/SectionHeading";
import SectionHead from "../../components/section/SectionHead";
import SectionContent from "../../components/section/SectionContent";
import useFarmer from "../../hooks/farmer/useFarmer";
import Icon from "../../components/icon/Icon";
import LoadingData from "../../components/data/LoadingData";

import SortableList, { SortableItem } from "react-easy-sort";
import arrayMoveImmutable from "array-move";
import FormSubmit from "../../components/form/FormSubmit";
import Form from "../../components/form/Form";
import FormError from "../../components/form/FormError";
import FormLink from "../../components/form/FormLink";
import apiRequest from "../../api/apiRequest";

const FarmerSort = () => {
  const [formLoading, setLoadingData] = useState(false);
  const [formError, setFormError] = useState();

  const { farmers, farmersLoading } = useFarmer();
  const [farmerOrder, setFarmerOrder] = useState();
  useEffect(() => {
    if (farmers) {
      setFarmerOrder(farmers);
    }
  }, [farmers]);

  const onSortEnd = (oldIndex, newIndex) => {
    setFarmerOrder((array) => arrayMoveImmutable(array, oldIndex, newIndex));
  };

  const handleFormSubmit = async () => {
    setLoadingData(true);
    setFormError(false);
    const request = {
      ids: farmerOrder.map((obj) => obj._id),
    };
    const response = await apiRequest.post("/farmer/sort", request);
    setLoadingData(false);
    if (response.status_code === 200) {
      window.location.href = "/farmer/view";
    } else {
      setFormError(response.error);
    }
  };
  return (
    <Section>
      <SectionHead>
        <SectionHeading>Sortiraj Farmere</SectionHeading>
      </SectionHead>
      <SectionContent>
        {farmersLoading ? (
          <LoadingData />
        ) : (
          <Form>
            {farmerOrder && farmerOrder.length > 0 ? (
              <>
                <SortableList
                  as="ul"
                  className="flex flex-col gap-4"
                  onSortEnd={onSortEnd}
                  lockAxis="y"
                  draggedItemClassName="border border-theme-color--primary"
                >
                  {farmerOrder.map((farmer, key) => {
                    return (
                      <SortableItem key={key}>
                        <li className="bg-white border cursor-grab flex gap-4 p-4 rounded-lg sm:p-5">
                          <div className="text-xl">
                            <Icon icon={"user-cowboy"} />
                          </div>
                          <h2 className="font-medium">
                            {key + 1}. {farmer.first_name} {farmer.last_name}
                          </h2>
                        </li>
                      </SortableItem>
                    );
                  })}
                </SortableList>
                <FormSubmit
                  onClick={() => handleFormSubmit()}
                  isLoading={formLoading}
                >
                  Soritraj
                </FormSubmit>
              </>
            ) : (
              <>
                <FormError>Nema Farmera za sortiranje</FormError>
                <FormLink to={"/farmer/add"}>Dodaj Farmera</FormLink>
              </>
            )}
          </Form>
        )}
      </SectionContent>
    </Section>
  );
};

export default FarmerSort;
