import React from "react";
import toFixedNumber from "../../functions/toFixedNumber";
import toDateLot from "../../functions/toDateLot";
import Icon from "../icon/Icon";

const ProductionItem = ({ production }) => {
  let icon, heading, unit;
  const productAmount = toFixedNumber(production.amount);
  const productWarehouse = production.warehouse;
  const productLOT = toDateLot(new Date(productWarehouse.date.produced));
  const product = productWarehouse.product;
  const productType = productWarehouse.product_type;
  const productName = product.name;
  const productUnit = product.unit;

  if (productType === "ProductPackage") {
    icon = "bucket";
    heading =
      productName +
      " (" +
      production.warehouse.product.amount +
      productUnit +
      ")";
    unit = "kom";
  } else if (productType === "ProductMaterial") {
    icon = "bag-seedling";
    heading = productName;
    unit = productUnit;
  } else if (productType === "ProductRaw") {
    icon = "droplet";
    heading = productName;
    unit = productUnit;
  } else if (productType === "Product") {
    icon = "box";
    heading =
      productName +
      " (" +
      production.warehouse.product.package.amount +
      production.warehouse.product.package.unit +
      ")";
    unit = productUnit;
  }
  return (
    <li className="border flex flex-col gap-4 items-center justify-center gap-4 p-4 rounded-lg text-center w-full">
      <div className="text-2xl">
        <Icon icon={icon} />
      </div>
      <div className="flex flex-col gap-2">
        <h3 className="font-medium">{heading}</h3>
        <div>
          <div>
            LOT: <span className="font-medium">{productLOT}</span>
          </div>
          <div>
            Količina:{" "}
            <span className="font-medium">
              {productAmount}
              {unit}
            </span>
          </div>
        </div>
      </div>
    </li>
  );
};

export default ProductionItem;
