import React from "react";
import { Outlet } from "react-router-dom";
import logo from "../../assets/media/logo.svg";

const GuestLayout = () => {
  return (
    <div className="flex flex-col h-theme-height--svh">
      <header className="bg-theme-color--header flex h-theme-height--header items-center justify-center">
        <img className="h-12" src={logo} />
      </header>
      <main className="flex items-center justify-center flex-1 p-4">
        <Outlet />
      </main>
    </div>
  );
};

export default GuestLayout;
