import React, { useEffect, useState } from "react";
import Section from "../../components/section/Section";
import SectionHeading from "../../components/typography/SectionHeading";
import SectionHead from "../../components/section/SectionHead";
import SectionContent from "../../components/section/SectionContent";
import LoadingData from "../../components/data/LoadingData";
import FormError from "../../components/form/FormError";
import Form from "../../components/form/Form";
import toDateReadable from "../../functions/toDateReadable";
import ProductionItem from "../../components/list/ProductionItem";
import useCookie from "../../hooks/useCookie";
import toDateFormated from "../../functions/toDateFormated";
import apiRequest from "../../api/apiRequest";
import FormInput from "../../components/form/FormInput";

const WorkView = () => {
  const [formLoading, setLoadingData] = useState(true);
  const [formError, setFormError] = useState();

  const grouped = [];

  const [workDateStartCookie, setWorkDateStartCookie] = useCookie(
    "work-view-date-start"
  );
  const [workDateEndCookie, setWorkDateEndCookie] =
    useCookie("work-view-date-end");

  const [workDateStart, setWorkDateStart] = useState();
  const [workDateEnd, setWorkDateEnd] = useState();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const dateParam = urlParams.get("date");
    if (dateParam) {
      setWorkDateStart(dateParam);
      setWorkDateEnd(dateParam);
    } else {
      setWorkDateStart(
        workDateStartCookie ? workDateStartCookie : toDateFormated()
      );
      setWorkDateEnd(workDateEndCookie ? workDateEndCookie : toDateFormated());
    }
  }, []);

  const [workData, setWorkData] = useState();

  function groupProductionByDate(objects) {
    objects.forEach((obj) => {
      const date = obj.date;
      const index = grouped.findIndex((entry) => entry.date === date);

      if (index === -1) {
        if (obj.production_type === "Produced") {
          grouped.push({ date: date, produced: [obj], used: [], sold: [] });
        } else if (obj.production_type === "Used") {
          grouped.push({ date: date, produced: [], used: [obj], sold: [] });
        }
      } else {
        if (obj.production_type === "Produced") {
          grouped[index].produced.push(obj);
        } else if (obj.production_type === "Used") {
          grouped[index].used.push(obj);
        }
      }
    });
  }

  function groupSaleByDate(objects) {
    objects.forEach((obj) => {
      const date = obj.date;
      const index = grouped.findIndex((entry) => entry.date === date);

      if (index === -1) {
        grouped.push({ date: date, produced: [], used: [], sold: [obj] });
      } else {
        grouped[index].sold.push(obj);
      }
    });
  }

  const handleWorkDateStartChange = (date) => {
    const isValidDate = new Date(date);
    if (!isNaN(isValidDate)) {
      setWorkDateStart(date);
      setWorkDateStartCookie(date);
    }
  };

  const handleWorkDateEndChange = (date) => {
    const isValidDate = new Date(date);
    if (!isNaN(isValidDate)) {
      setWorkDateEnd(date);
      setWorkDateEndCookie(date);
    }
  };

  const getProductions = async () => {
    const response = await apiRequest.get(
      `/production/get?date_start=${workDateStart}&date_end=${workDateEnd}`
    );
    if (response.status_code === 200) {
      const result = response.result;
      return groupProductionByDate(result);
    } else {
      setFormError(response.error);
    }
  };

  const getSales = async () => {
    const response = await apiRequest.get(
      `/sale/get?date_start=${workDateStart}&date_end=${workDateEnd}`
    );
    if (response.status_code === 200) {
      const result = response.result;
      return groupSaleByDate(result);
    } else {
      setFormError(response.error);
    }
  };

  useEffect(() => {
    if (workDateStart && workDateEnd) {
      const getData = async () => {
        setFormError(false);
        setLoadingData(true);
        await getProductions();
        await getSales();
        setLoadingData(false);
        setWorkData(grouped);
      };
      getData();
    }
  }, [workDateStart, workDateEnd]);

  return (
    <Section>
      <SectionHead>
        <SectionHeading>Pregledaj Rad</SectionHeading>
      </SectionHead>
      <SectionContent>
        <Form>
          <FormInput
            type="date"
            icon={"calendar-day"}
            value={workDateStart}
            placeholder={"Početni Datum"}
            onChange={(e) => {
              handleWorkDateStartChange(e.target.value);
            }}
          />
          <FormInput
            type="date"
            icon={"calendar-day"}
            value={workDateEnd}
            placeholder={"Završni Datum"}
            onChange={(e) => {
              handleWorkDateEndChange(e.target.value);
            }}
          />
          {formLoading ? (
            <LoadingData />
          ) : (
            <>
              {workData && workData.length > 0 ? (
                <ul className="grid gap-4">
                  {workData.map((productionGroup, key) => {
                    const productionGroupDate = productionGroup.date;
                    const productionGroupDateReadable = toDateReadable(
                      new Date(productionGroupDate)
                    );
                    const productionGroupProduced = productionGroup.produced;
                    const productionGroupUsed = productionGroup.used;
                    const productionGroupSold = productionGroup.sold;
                    return (
                      <div
                        key={key}
                        className="border flex flex-col gap-4 items-center p-4 rounded-lg sm:p-6 md:gap-8 md:p-8 lg:p-10"
                      >
                        <h1 className="text-lg md:text-xl">
                          Datum: {productionGroupDateReadable}
                        </h1>
                        <div className="flex flex-col gap-4 w-full md:flex-row">
                          <div className="border flex-1 rounded-lg">
                            <h2 className="bg-red-700 font-bold text-theme-color--primaryContrast p-4 rounded-t-lg text-center md:text-lg">
                              Utrošeno
                            </h2>
                            <ul className="flex-1 flex flex-col gap-4 items-center p-4">
                              {productionGroupUsed.map((production, key) => {
                                return (
                                  <ProductionItem
                                    key={key}
                                    production={production}
                                  />
                                );
                              })}
                            </ul>
                          </div>
                          <div className="border flex-1 rounded-lg">
                            <h2 className="bg-theme-color--primary font-bold text-theme-color--primaryContrast p-4 rounded-t-lg text-center md:text-lg">
                              Proizvedeno
                            </h2>
                            <ul className="flex-1 flex flex-col gap-4 items-center p-4">
                              {productionGroupProduced.map(
                                (production, key) => {
                                  return (
                                    <ProductionItem
                                      key={key}
                                      production={production}
                                    />
                                  );
                                }
                              )}
                            </ul>
                          </div>

                          <div className="border flex-1 rounded-lg">
                            <h2 className="bg-green-700 font-bold text-theme-color--primaryContrast p-4 rounded-t-lg text-center md:text-lg">
                              Prodato
                            </h2>
                            <ul className="flex-1 flex flex-col gap-4 items-center p-4">
                              {productionGroupSold.map((production, key) => {
                                return (
                                  <ProductionItem
                                    key={key}
                                    production={production}
                                  />
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </ul>
              ) : (
                <FormError>Nema Rad za Pregledavanje</FormError>
              )}
            </>
          )}
        </Form>
      </SectionContent>
    </Section>
  );
};

export default WorkView;
