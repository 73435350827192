import React, { useEffect, useState } from "react";
import FormInputHolder from "../../../components/form/FormInputHolder";
import FormSelect from "../../../components/form/FormSelect";
import FormInput from "../../../components/form/FormInput";
import FormSubmit from "../../../components/form/FormSubmit";
import Section from "../../../components/section/Section";
import Form from "../../../components/form/Form";
import useProductSelect from "../../../hooks/useProductSelect";
import FormNotice from "../../../components/form/FormNotice";
import SectionHeading from "../../../components/typography/SectionHeading";
import toFixedNumber from "../../../functions/toFixedNumber";
import SectionHead from "../../../components/section/SectionHead";
import SectionContent from "../../../components/section/SectionContent";
import FormError from "../../../components/form/FormError";
import useCookie from "../../../hooks/useCookie";
import toDateFormated from "../../../functions/toDateFormated";
import useWarehouseProduct from "../../../hooks/useWarehouseProduct";
import apiRequest from "../../../api/apiRequest";
import FormLink from "../../../components/form/FormLink";

const ProduceButter = () => {
  const [formLoading, setFormLoading] = useState(false);
  const [formError, setFormError] = useState();
  const [isProduceable, setIsProduceable] = useState(false);
  const [productionDate, setProductionDate] = useCookie("production-date");
  const [cream40ID, setCream40ID] = useState();
  const [cream40Amount, setCream40Amount] = useState();

  const [producesButter, setProducesButter] = useState();
  const [producesCream40, setProducesCream40] = useState();

  const {
    warehouseProducts: warehouseCream40,
    warehouseRefetch: warehouseCream40Refetch,
  } = useWarehouseProduct(
    `product=6608b3b9a98f69aa0dab1431&amount=unused&date_produced=${productionDate}`
  );
  const warehouseCream40Select = useProductSelect(warehouseCream40, "kg");

  useEffect(() => {
    if (warehouseCream40 && warehouseCream40.length > 0) {
      const initialWarehouseCream40 = warehouseCream40[0];
      const initialWarehouseCream40ID = initialWarehouseCream40._id;
      setCream40ID(initialWarehouseCream40ID);
    } else {
      setIsProduceable(false);
    }
  }, [warehouseCream40]);

  useEffect(() => {
    if (cream40ID) {
      const selectedProduct = warehouseCream40.find(
        (product) => product._id === cream40ID
      );
      const maxProductAmount =
        selectedProduct.amount.produced - selectedProduct.amount.used;
      if (cream40Amount > maxProductAmount) {
        setCream40Amount(maxProductAmount);
      } else {
        setCream40Amount(cream40Amount);
      }
    }
  }, [cream40Amount]);

  useEffect(() => {
    if (cream40Amount && cream40Amount > 0) {
      const producesButter = toFixedNumber((cream40Amount * 40) / 82);
      setIsProduceable(true);
      setProducesButter(producesButter);
      setProducesCream40(cream40Amount - producesButter);
    } else {
      setIsProduceable(false);
      setProducesButter("");
      setProducesCream40("");
    }
  }, [cream40Amount]);

  useEffect(() => {
    warehouseCream40Refetch();
  }, [productionDate]);

  const handleProductionDateChange = (date) => {
    setProductionDate(date, 1);
  };

  const handleProductionSubmit = async () => {
    setFormError("");
    setFormLoading(true);
    const request = {
      produced: [
        {
          product: "66097824ec44f58429269729",
          product_type: "ProductRaw",
          amount: producesButter,
        },
      ],
      used: [{ product: cream40ID, amount: cream40Amount }],
      date: { produced: new Date(productionDate) },
    };

    const response = await apiRequest.post("/production/add", request);

    if (response.status_code === 200) {
      window.location.reload();
    } else {
      setFormError(response.error);
    }
  };

  return (
    <Section>
      <SectionHead>
        <SectionHeading>Proizvedi - Maslac</SectionHeading>
      </SectionHead>
      <SectionContent>
        <Form>
          <FormInput
            type="date"
            icon={"calendar-day"}
            value={productionDate ? productionDate : toDateFormated()}
            placeholder={"Datum Proizvodnje"}
            onChange={(e) => {
              handleProductionDateChange(e.target.value);
            }}
          />
          <FormInputHolder>
            {warehouseCream40 && warehouseCream40.length > 0 ? (
              <>
                <FormSelect
                  placeholder={"Pavlaka Poluproizvod 40% m.m"}
                  options={warehouseCream40Select}
                  value={cream40ID}
                  onChange={(e) => {
                    setCream40ID(e.target.value);
                  }}
                />
                <FormInput
                  icon={"scale-balanced"}
                  placeholder={"Količina (KG)"}
                  type="number"
                  step="0.5"
                  min={1}
                  value={cream40Amount}
                  onChange={(e) => {
                    const input = e.target.value;
                    if (/^\d*\.?\d{0,2}$/.test(input)) {
                      setCream40Amount(input);
                    }
                  }}
                />
              </>
            ) : (
              <>
                <FormError>
                  Nedostaje Pavlaka Poluproizvod 40% m.m za Proizvodnju
                </FormError>
                <FormLink to={"/production/produce/cream-40"}>
                  Proizvedi Pavlaku Poluproizvod 40% m.m
                </FormLink>
              </>
            )}
          </FormInputHolder>
          {producesButter && (
            <FormInputHolder>
              <FormInput
                icon={"scale-balanced"}
                placeholder={"Proizvedi (KG)"}
                type="number"
                min={1}
                value={producesButter}
                onChange={(e) => {
                  const input = e.target.value;
                  if (/^\d*\.?\d{0,2}$/.test(input)) {
                    setProducesButter(input);
                  }
                }}
              />
              <FormNotice>Proizvedi {producesButter}kg Maslaca</FormNotice>
            </FormInputHolder>
          )}
          {isProduceable && (
            <FormSubmit
              onClick={handleProductionSubmit}
              isLoading={formLoading}
            >
              Proizvedi
            </FormSubmit>
          )}
        </Form>
      </SectionContent>
    </Section>
  );
};

export default ProduceButter;
