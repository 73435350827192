import React, { useEffect, useState } from "react";
import Section from "../../../components/section/Section";
import SectionHead from "../../../components/section/SectionHead";
import SectionHeading from "../../../components/typography/SectionHeading";
import SectionContent from "../../../components/section/SectionContent";
import FormInput from "../../../components/form/FormInput";
import toDateFormated from "../../../functions/toDateFormated";
import useCookie from "../../../hooks/useCookie";
import Form from "../../../components/form/Form";
import FormInputHolder from "../../../components/form/FormInputHolder";
import FormSelect from "../../../components/form/FormSelect";
import useWarehouseProduct from "../../../hooks/useWarehouseProduct";
import useProductSelect from "../../../hooks/useProductSelect";
import FormError from "../../../components/form/FormError";
import FormLink from "../../../components/form/FormLink";
import FormSubmit from "../../../components/form/FormSubmit";
import apiRequest from "../../../api/apiRequest";
import FormNotice from "../../../components/form/FormNotice";

const PackCreamSpread = () => {
  const [isProduceable, setIsProduceable] = useState(false);
  const [packingDate, setPackingDate] = useCookie("packing-date");
  const [formLoading, setFormLoading] = useState(false);
  const [formError, setFormError] = useState();

  const [creamSpreadID, setCreamSpreadID] = useState();
  const [creamSpreadAmount, setCreamSpreadAmount] = useState();

  const [package015ID, setPackage015ID] = useState();
  const [package015Amount, setPackage015Amount] = useState("");

  const [package025ID, setPackage025ID] = useState();
  const [package025Amount, setPackage025Amount] = useState("");

  const [package04ID, setPackage04ID] = useState();
  const [package04Amount, setPackage04Amount] = useState("");

  const [package05ID, setPackage05ID] = useState();
  const [package05Amount, setPackage05Amount] = useState("");

  const [package1ID, setPackage1ID] = useState();
  const [package1Amount, setPackage1Amount] = useState("");

  const {
    warehouseProducts: warehouseCreamSpread,
    warehouseRefetch: warehouseCreamSpreadRefetch,
  } = useWarehouseProduct(
    `product=6609752dec44f58429269724&amount=unused&date_produced=${packingDate}`
  );
  const warehouseCreamSpreadSelect = useProductSelect(
    warehouseCreamSpread,
    "kg"
  );

  const {
    warehouseProducts: warehousePackage015,
    warehouseRefetch: warehousePackage015Refetch,
  } = useWarehouseProduct(
    `product=66044f544c0de58713d192be&amount=unused&date_produced=${packingDate}`
  );
  const warehousePackage015Select = useProductSelect(
    warehousePackage015,
    "kom"
  );

  const {
    warehouseProducts: warehousePackage025,
    warehouseRefetch: warehousePackage025Refetch,
  } = useWarehouseProduct(
    `product=660f2fb249a1b746df531abf&amount=unused&date_produced=${packingDate}`
  );
  const warehousePackage025Select = useProductSelect(
    warehousePackage025,
    "kom"
  );

  const {
    warehouseProducts: warehousePackage04,
    warehouseRefetch: warehousePackage04Refetch,
  } = useWarehouseProduct(
    `product=660f2fbb49a1b746df531ac0&amount=unused&date_produced=${packingDate}`
  );
  const warehousePackage04Select = useProductSelect(warehousePackage04, "kom");

  const {
    warehouseProducts: warehousePackage05,
    warehouseRefetch: warehousePackage05Refetch,
  } = useWarehouseProduct(
    `product=660f2fc249a1b746df531ac1&amount=unused&date_produced=${packingDate}`
  );
  const warehousePackage05Select = useProductSelect(warehousePackage05, "kom");

  const {
    warehouseProducts: warehousePackage1,
    warehouseRefetch: warehousePackage1Refetch,
  } = useWarehouseProduct(
    `product=660f2fd449a1b746df531ac3&amount=unused&date_produced=${packingDate}`
  );
  const warehousePackage1Select = useProductSelect(warehousePackage1, "kom");

  useEffect(() => {
    if (warehouseCreamSpread && warehouseCreamSpread.length > 0) {
      const initialWarehouseCreamSpread = warehouseCreamSpread[0];
      const initialWarehouseCreamSpreadID = initialWarehouseCreamSpread._id;
      setCreamSpreadID(initialWarehouseCreamSpreadID);
    } else {
      setIsProduceable(false);
    }
  }, [warehouseCreamSpread]);

  useEffect(() => {
    if (warehousePackage015 && warehousePackage015.length > 0) {
      const initialWarehousePackage015 = warehousePackage015[0];
      const initialWarehousePackage015ID = initialWarehousePackage015._id;
      setPackage015ID(initialWarehousePackage015ID);
    }
  }, [warehousePackage015]);

  useEffect(() => {
    if (warehousePackage025 && warehousePackage025.length > 0) {
      const initialWarehousePackage025 = warehousePackage025[0];
      const initialWarehousePackage025ID = initialWarehousePackage025._id;
      setPackage025ID(initialWarehousePackage025ID);
    }
  }, [warehousePackage025]);

  useEffect(() => {
    if (warehousePackage04 && warehousePackage04.length > 0) {
      const initialWarehousePackage04 = warehousePackage04[0];
      const initialWarehousePackage04ID = initialWarehousePackage04._id;
      setPackage04ID(initialWarehousePackage04ID);
    }
  }, [warehousePackage04]);

  useEffect(() => {
    if (warehousePackage05 && warehousePackage05.length > 0) {
      const initialWarehousePackage05 = warehousePackage05[0];
      const initialWarehousePackage05ID = initialWarehousePackage05._id;
      setPackage05ID(initialWarehousePackage05ID);
    }
  }, [warehousePackage05]);

  useEffect(() => {
    if (warehousePackage1 && warehousePackage1.length > 0) {
      const initialWarehousePackage1 = warehousePackage1[0];
      const initialWarehousePackage1ID = initialWarehousePackage1._id;
      setPackage1ID(initialWarehousePackage1ID);
    }
  }, [warehousePackage1]);

  useEffect(() => {
    warehouseCreamSpreadRefetch();
    warehousePackage015Refetch();
    warehousePackage025Refetch();
    warehousePackage04Refetch();
    warehousePackage05Refetch();
    warehousePackage1Refetch();
  }, [packingDate]);

  useEffect(() => {
    if (
      package015Amount > 0 ||
      package025Amount > 0 ||
      package04Amount > 0 ||
      package05Amount > 0 ||
      package1Amount > 0
    ) {
      setIsProduceable(true);
      setCreamSpreadAmount(
        package015Amount * 0.15 +
          package025Amount * 0.25 +
          package04Amount * 0.4 +
          package05Amount * 0.5 +
          package1Amount * 1
      );
    } else {
      setIsProduceable(false);
      setCreamSpreadAmount(0);
    }
  }, [
    package015Amount,
    package025Amount,
    package04Amount,
    package05Amount,
    package1Amount,
  ]);

  const handleProductionDateChange = (date) => {
    setPackingDate(date, 1);
  };

  const maxPackageSizeAmount = (packageSize) => {
    const selectedProduct = warehouseCreamSpread.find(
      (product) => product._id === creamSpreadID
    );
    const maxProductAmount =
      selectedProduct.amount.produced - selectedProduct.amount.used;
    const avaliablePackageAmount = Math.floor(
      (maxProductAmount -
        (packageSize === 0.15 ? 0 : package015Amount * 0.15) -
        (packageSize === 0.25 ? 0 : package025Amount * 0.25) -
        (packageSize === 0.4 ? 0 : package04Amount * 0.4) -
        (packageSize === 0.5 ? 0 : package05Amount * 0.5) -
        (packageSize === 1 ? 0 : package1Amount * 1)) /
        packageSize
    );
    return avaliablePackageAmount;
  };

  const handlePackage015AmountChange = (input) => {
    const selectedPackage015 = warehousePackage015.find(
      (product) => product._id === package015ID
    );
    const maxPackageAmount015 =
      selectedPackage015.amount.produced - selectedPackage015.amount.used;

    const packageAmount = maxPackageSizeAmount(0.15);

    if (input > maxPackageAmount015) {
      if (maxPackageAmount015 > packageAmount) {
        setPackage015Amount(packageAmount);
      } else {
        setPackage015Amount(maxPackageAmount015);
      }
    } else {
      if (input > packageAmount) {
        setPackage015Amount(packageAmount);
      } else {
        setPackage015Amount(input);
      }
    }
  };

  const handlePackage025AmountChange = (input) => {
    const selectedPackage025 = warehousePackage025.find(
      (product) => product._id === package025ID
    );
    const maxPackageAmount025 =
      selectedPackage025.amount.produced - selectedPackage025.amount.used;

    const packageAmount = maxPackageSizeAmount(0.25);

    if (input > maxPackageAmount025) {
      if (maxPackageAmount025 > packageAmount) {
        setPackage025Amount(packageAmount);
      } else {
        setPackage025Amount(maxPackageAmount025);
      }
    } else {
      if (input > packageAmount) {
        setPackage025Amount(packageAmount);
      } else {
        setPackage025Amount(input);
      }
    }
  };

  const handlePackage04AmountChange = (input) => {
    const selectedPackage04 = warehousePackage04.find(
      (product) => product._id === package04ID
    );
    const maxPackageAmount04 =
      selectedPackage04.amount.produced - selectedPackage04.amount.used;

    const packageAmount = maxPackageSizeAmount(0.4);

    if (input > maxPackageAmount04) {
      if (maxPackageAmount04 > packageAmount) {
        setPackage04Amount(packageAmount);
      } else {
        setPackage04Amount(maxPackageAmount04);
      }
    } else {
      if (input > packageAmount) {
        setPackage04Amount(packageAmount);
      } else {
        setPackage04Amount(input);
      }
    }
  };

  const handlePackage05AmountChange = (input) => {
    const selectedPackage05 = warehousePackage05.find(
      (product) => product._id === package05ID
    );
    const maxPackageAmount05 =
      selectedPackage05.amount.produced - selectedPackage05.amount.used;

    const packageAmount = maxPackageSizeAmount(0.5);

    if (input > maxPackageAmount05) {
      if (maxPackageAmount05 > packageAmount) {
        setPackage05Amount(packageAmount);
      } else {
        setPackage05Amount(maxPackageAmount05);
      }
    } else {
      if (input > packageAmount) {
        setPackage05Amount(packageAmount);
      } else {
        setPackage05Amount(input);
      }
    }
  };

  const handlePackage1AmountChange = (input) => {
    const selectedPackage1 = warehousePackage1.find(
      (product) => product._id === package1ID
    );
    const maxPackageAmount1 =
      selectedPackage1.amount.produced - selectedPackage1.amount.used;

    const packageAmount = maxPackageSizeAmount(1);

    if (input > maxPackageAmount1) {
      if (maxPackageAmount1 > packageAmount) {
        setPackage1Amount(packageAmount);
      } else {
        setPackage1Amount(maxPackageAmount1);
      }
    } else {
      if (input > packageAmount) {
        setPackage1Amount(packageAmount);
      } else {
        setPackage1Amount(input);
      }
    }
  };

  const handleProductionSubmit = async () => {
    setFormError("");
    setFormLoading(true);
    const produced = [];
    const used = [];
    const producedDate = new Date(packingDate);
    const expiresDate = new Date(packingDate);
    expiresDate.setDate(expiresDate.getDate() + 60);
    if (package015Amount > 0) {
      produced.push({
        product: "66100fd1fac9b067b8f69764",
        product_type: "Product",
        amount: package015Amount,
      });
      used.push({ product: package015ID, amount: package015Amount });
    }
    if (package025Amount > 0) {
      produced.push({
        product: "66101023fac9b067b8f69765",
        product_type: "Product",
        amount: package025Amount,
      });
      used.push({ product: package025ID, amount: package025Amount });
    }
    if (package04Amount > 0) {
      produced.push({
        product: "6610102cfac9b067b8f69766",
        product_type: "Product",
        amount: package04Amount,
      });
      used.push({ product: package04ID, amount: package04Amount });
    }
    if (package05Amount > 0) {
      produced.push({
        product: "66101039fac9b067b8f69767",
        product_type: "Product",
        amount: package05Amount,
      });
      used.push({ product: package05ID, amount: package05Amount });
    }
    if (package1Amount > 0) {
      produced.push({
        product: "66101049fac9b067b8f69768",
        product_type: "Product",
        amount: package1Amount,
      });
      used.push({ product: package1ID, amount: package1Amount });
    }
    used.push({
      product: creamSpreadID,
      amount: creamSpreadAmount,
    });
    const request = {
      produced,
      used,
      date: { produced: producedDate, expires: expiresDate },
    };

    const response = await apiRequest.post("/production/add", request);

    if (response.status_code === 200) {
      window.location.href = `/work/view?date=${packingDate}`;
    } else {
      setFormError(response.error);
    }
  };

  return (
    <Section>
      <SectionHead>
        <SectionHeading>Pakuj - Kajmak Namaz</SectionHeading>
      </SectionHead>
      <SectionContent>
        <Form>
          <FormInput
            type="date"
            icon={"calendar-day"}
            value={packingDate ? packingDate : toDateFormated()}
            placeholder={"Datum Pakovanja"}
            onChange={(e) => {
              handleProductionDateChange(e.target.value);
            }}
          />
          <FormInputHolder>
            {warehouseCreamSpread && warehouseCreamSpread.length > 0 ? (
              <>
                <FormSelect
                  placeholder={"Kajmak Namaz"}
                  options={warehouseCreamSpreadSelect}
                  value={creamSpreadID}
                  onChange={(e) => {
                    setCreamSpreadID(e.target.value);
                  }}
                />
                <FormInputHolder>
                  {warehousePackage015 && warehousePackage015.length > 0 ? (
                    <>
                      <FormSelect
                        placeholder={"Ambalaža 0.15kg"}
                        options={warehousePackage015Select}
                        value={package015ID}
                        onChange={(e) => {
                          setCreamSpreadID(e.target.value);
                        }}
                      />
                      <FormInput
                        icon={"scale-balanced"}
                        placeholder={"Količina (KOM)"}
                        type="number"
                        step="1"
                        min={1}
                        value={package015Amount}
                        onChange={(e) => {
                          const input = e.target.value;
                          handlePackage015AmountChange(parseInt(input));
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <FormError>
                        Nedostaje Ambalaža 0.15kg za Pakovanje
                      </FormError>
                      <FormLink to={"/warehouse/add/package"}>
                        Dodaj Ambalažu 0.15kg
                      </FormLink>
                    </>
                  )}
                </FormInputHolder>
                <FormInputHolder>
                  {warehousePackage025 && warehousePackage025.length > 0 ? (
                    <>
                      <FormSelect
                        placeholder={"Ambalaža 0.25kg"}
                        options={warehousePackage025Select}
                        value={package025ID}
                        onChange={(e) => {
                          setCreamSpreadID(e.target.value);
                        }}
                      />
                      <FormInput
                        icon={"scale-balanced"}
                        placeholder={"Količina (KOM)"}
                        type="number"
                        step="1"
                        min={1}
                        value={package025Amount}
                        onChange={(e) => {
                          const input = e.target.value;
                          handlePackage025AmountChange(parseInt(input));
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <FormError>
                        Nedostaje Ambalaža 0.25kg za Pakovanje
                      </FormError>
                      <FormLink to={"/warehouse/add/package"}>
                        Dodaj Ambalažu 0.25kg
                      </FormLink>
                    </>
                  )}
                </FormInputHolder>
                <FormInputHolder>
                  {warehousePackage04 && warehousePackage04.length > 0 ? (
                    <>
                      <FormSelect
                        placeholder={"Ambalaža 0.4kg"}
                        options={warehousePackage04Select}
                        value={package04ID}
                        onChange={(e) => {
                          setCreamSpreadID(e.target.value);
                        }}
                      />
                      <FormInput
                        icon={"scale-balanced"}
                        placeholder={"Količina (KOM)"}
                        type="number"
                        step="1"
                        min={1}
                        value={package04Amount}
                        onChange={(e) => {
                          const input = e.target.value;
                          handlePackage04AmountChange(parseInt(input));
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <FormError>
                        Nedostaje Ambalaža 0.4kg za Pakovanje
                      </FormError>
                      <FormLink to={"/warehouse/add/package"}>
                        Dodaj Ambalažu 0.4kg
                      </FormLink>
                    </>
                  )}
                </FormInputHolder>
                <FormInputHolder>
                  {warehousePackage05 && warehousePackage05.length > 0 ? (
                    <>
                      <FormSelect
                        placeholder={"Ambalaža 0.5kg"}
                        options={warehousePackage05Select}
                        value={package05ID}
                        onChange={(e) => {
                          setCreamSpreadID(e.target.value);
                        }}
                      />
                      <FormInput
                        icon={"scale-balanced"}
                        placeholder={"Količina (KOM)"}
                        type="number"
                        step="1"
                        min={1}
                        value={package05Amount}
                        onChange={(e) => {
                          const input = e.target.value;
                          handlePackage05AmountChange(parseInt(input));
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <FormError>
                        Nedostaje Ambalaža 0.5kg za Pakovanje
                      </FormError>
                      <FormLink to={"/warehouse/add/package"}>
                        Dodaj Ambalažu 0.5kg
                      </FormLink>
                    </>
                  )}
                </FormInputHolder>
                <FormInputHolder>
                  {warehousePackage1 && warehousePackage1.length > 0 ? (
                    <>
                      <FormSelect
                        placeholder={"Ambalaža 1kg"}
                        options={warehousePackage1Select}
                        value={package1ID}
                        onChange={(e) => {
                          setCreamSpreadID(e.target.value);
                        }}
                      />
                      <FormInput
                        icon={"scale-balanced"}
                        placeholder={"Količina (KOM)"}
                        type="number"
                        step="1"
                        min={1}
                        value={package1Amount}
                        onChange={(e) => {
                          const input = e.target.value;
                          handlePackage1AmountChange(parseInt(input));
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <FormError>Nedostaje Ambalaža 1kg za Pakovanje</FormError>
                      <FormLink to={"/warehouse/add/package"}>
                        Dodaj Ambalažu 1kg
                      </FormLink>
                    </>
                  )}
                </FormInputHolder>
              </>
            ) : (
              <>
                <FormError>Nedostaje Kajmak Namaz za Pakovanje</FormError>
                <FormLink to={"/production/produce/cream-spread"}>
                  Proizvedi Kajmak Namaz
                </FormLink>
              </>
            )}
          </FormInputHolder>
          {creamSpreadAmount > 0 && (
            <FormNotice>Spakuj {creamSpreadAmount}kg Kajmak Namaz</FormNotice>
          )}
          {isProduceable && (
            <FormSubmit
              onClick={handleProductionSubmit}
              isLoading={formLoading}
            >
              Pakuj
            </FormSubmit>
          )}
        </Form>
      </SectionContent>
    </Section>
  );
};

export default PackCreamSpread;
